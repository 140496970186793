.external-status {
  margin-bottom: 0;
  .stu-detail-app-collapse-date-txt {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #898d96;
  }

  .admin-stu-detail-collapse-sec .external-vendor-fail-container {
    background-color: #fef2f4;
    padding: 10px;
  }

  .stu-detail-border-bottom {
    background: #ffffff;
    border-bottom: 1px solid #e5e9f0;
  }

  .stu-detail-terms-collapse-text-grey p {
    font-weight: 600;
    font-size: 14px;
    color: #898d96;
  }

  .type-status {
    p {
      font-weight: 600 !important;
    font-size: 13px !important;
    }
  }

  .ext-int-status-badge-main-div {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-end;
  }

  .view-external-detail-badge {
    color: #367bda;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    padding: 6px 8px;
    background-color: #f2f9fe;
    border-radius: 6px;
    cursor: pointer;
    margin: 5px;
  }
  .student-status-funded,
  .student-status-failed,
  .student-status-cancelled,
  .student-status-approval,
  .student-status-pending,
  .student-status-approved {
    border-radius: 4px;
    font-weight: 700;
    padding: 3px 8px 5px;
    margin: 5px;
  }
  .external-vendor-fail-container {
    background-color: #fef2f4;
    padding: 10px;
}

  .student-status-badge-padding {
    padding: 6px 8px;
  }
}
