.qucksight-dashboard {
  background-color: #ffffff;

  header {
    background-color: #f1f1f1;
    padding: 20px;
    text-align: center;
  }

  h1 {
    margin: 0;
  }

  main {
    margin: 20px 20px 0 20px;
    text-align: center;
  }

  p {
    margin-bottom: 20px;
  }

  a {
    color: #000000;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  i {
    color: orange;
    font-style: normal;
  }
  .ant-tabs {
    width: 100%;
  }
  .dashboard-ref {
    height: calc(100vh - 176px) !important;
  }
}

.quicksight-tab-modal {
  .ant-modal-content {
    border-radius: 12px;

    .ant-modal-header {
      border-radius: 12px 12px 0 0;

      .ant-modal-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #2b323b;
      }
    }

    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .button-row {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      padding: 10px 0 0 0;
    }
  }
}

.quicksight-dashboard {
  background-color: #ffffff;

  .dashboard-ref {
    height: calc(100vh - 88px) !important;
  }

  .quicksight-header {
    background-color: #ffffff !important;
    padding: 20px 20px 20px 20px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.04);

    h2 {
      font-weight: 700;
      font-size: 30px;
      line-height: 41px;
      color: #2b323b;
    }

    .setting-icon {
      font-size: 20px !important;
    }

    .ant-tabs-nav {
      margin: 0 !important;

      .ant-tabs-tab {
        padding: 16px 0 8px 0;
      }
    }
  }
}
