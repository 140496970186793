.identity-doc-modal {
  width: 1000px !important;

  .ant-modal-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #2b323b;
  }
}
.identity-modal-btn .cancel {
  color: #2b323b !important;
  background-color: #edf0f5 !important;
}
.identity-modal-btn .cancel:hover {
  border-color: white !important;
  background-color: #edf0f5 !important ;
  color: #2b323b !important;
}
.identity-modal-btn .reupload-btn {
  width: 156px !important;
}

.actions-image-cont {
  height: 30px;
}
.actions-image-cont:hover {
  cursor: pointer;
}
.identity-doc-modal-notices {
  background: #fff9dc;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
}
.identity-doc-modal-notices li {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ff845e;
}
.identity-doc-modal-notices p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ff845e;
  padding-left: 10px;
}
.doc-identitty-modal-application {
  display: flex;
  gap: 20px;
  padding: 5px 0px 5px 0px;
}
.identity-approve-decline {
  display: flex;
  gap: 5px;
}
.identity-data-part {
  width: 100% !important;
}
.identity-detail-container {
  /* border-bottom: 1px solid #EDF0F5; */
  width: 100%;
}
.identity-detail-container-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: #656c7c;
}
.id-doc-phase {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #656c7c;
}
.button-to-reupload {
  border-color: #0049a4 !important;
  color: #0049a4 !important;
}
.image-card-identity {
  background-color: #f7f8fb;
  padding: 20px 0px 20px 20px;
  overflow-y: scroll;
  // height: 300px;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  /* scrollbar-color: red yellow; */
}
.image-card-col {
  display: flex !important;
  flex-direction: column !important;

  .ant-image {
    border: 1px solid #c3c6cd;
    border-radius: 4px;
    margin-bottom: 10px;
  }
}

.front-modal-col {
  .react-pdf__Page__canvas {
    border: 1px solid #c3c6cd;
    padding: 10px;
    border-radius: 4px;
  }
}

.front-modal-preview {
  color: #367bda;
  cursor: pointer;
}

.front-modal {
  .ant-modal-content {
    width: 680px !important;
  }
  .react-pdf__Page__canvas {
    max-width: 650px !important;
  }
}
.image-card-identity::-webkit-scrollbar {
  width: 10px;
}
.image-card-identity::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.image-card-identity::-webkit-scrollbar-thumb {
  background-color: #4492fc;

  border-radius: 40px;
}
.antd-select-identity {
  border-radius: 20px !important;
}
.input-reason-field {
  width: 100%;
  border: 1px solid #656c7c;
  border-radius: 8px;
  min-height: 36px;
}
.input-reason-field:focus {
  border: 1px solid #656c7c;
}
.input-reason-option {
  width: 200%;
  min-width: 2000px;
}

.approved-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-items: center;
  background-color: #e4faec;
  min-height: 40px;
  gap: 10px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  color: #25a050;
}
.approved-container p {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;

  color: #25a050;
}
.identity-imagecard-part {
  /* padding-left: 36px; */
  display: flex;
  /* gap: 50px; */
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 20px;
}
.identity-imagecard-part p {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #2b323b;
}
.identity-value {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.doc-modal-pageNo {
  display: flex;
  justify-content: flex-end;
}

.doc-detail .font-weight-bold {
  font-weight: 500;
  color: black;

  .doc-detail-heading {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #2b323b;
  }
}
.identity-modal-btn .approve-btn-doc,
.identity-modal-btn .decline-btn-doc {
  height: 42px;
  width: 120px;
  border-radius: 30px;
}
.identity-modal-btn .approve-btn-doc,
.approve-btn-certification {
  color: white;
  background: blue;
}

.identity-modal-btn .approve-btn-doc:disabled {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.25);
}
.identity-modal-btn .decline-btn-doc:disabled {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.25);
}

.identity-doc-modal .react-pdf__Page__canvas {
  /* width: 100%!important; */
  margin: auto;
  height: auto !important;
}

.identity-image {
  margin: auto;
  margin-bottom: 10px;
  padding: 12px;
}
