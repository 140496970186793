.approved-terms-card {
  border-right: 1px solid #edf0f5;
  padding-top: 12px;
  .approved-terms-heading {
    // min-height: 48px;
    align-items: center;
    padding: 0px 12px !important;
    .terms-heading-name {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      color: #2b323b;
    }
  }
  .approved-terms-amount-row {
    // min-height: 48px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px !important;
    .terms-amount-value {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      color: #25a050;
    }
    .terms-approved-date {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #656c7c;
    }
  }
  .card-rows-divider {
    margin: 0px !important;
  }
  .terms-details-data {
    justify-content: space-between;
    align-items: center;
    .approved-details-data {
      min-width: 100%;
      //   min-height: 50px;
      padding-top: 15px;
      padding: 12px 16px 2px 16px !important;
    }
    .inner-row {
      justify-content: space-between;
    }
    .right-span {
      display: flex;
      justify-content: flex-end;
    }
    .term-details-label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #656c7c;
    }
    .term-details-edit-date {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #656c7c;
    }
    .term-details-value {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
    .cancel-date-orange {
      font-size: 12px !important;
      color: #ff845e !important;
    }
    .cancel-date-green {
      font-size: 12px !important;
      color: #25a050 !important;
    }
  }
}
