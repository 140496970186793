.coding-school-main-container {
  background-color: #f5f5f5;

  .edly-blue-button-padding {
    padding: 0px 24px !important;
    height: 46px !important;
  }

  .btn-container {
    gap: 10px;
  }

  .application-table-headings {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #656c7c;
  }

  .ant-pagination-item {
    a {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .ant-pagination-item-active {
    background-color: #5fa1ff !important;
    border: 1px solid #5fa1ff !important;
    border-radius: 3px !important;
    color: #f2f9fe !important;
    text-align: center;

    a {
      color: #f2f9fe !important;
    }
  }

  .Active {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #5ccd83;
  }

  .Inactive {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #f22d55;
  }

  .Invited {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #2f74d3;
  }

  .coding-school-header-container {
    padding: 30px 16px;
    background-color: #ffffff;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.04);

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 41px;
      color: #2b323b;
    }
  }

  .add-new-school-container {
    margin-top: 5px;
    padding: 20px;
    background-color: #ffffff;

    .search-input {
      //   .ant-input-wrapper.ant-input-group {
      //   input {
      //     border-top-left-radius: 8px !important;
      //     border-bottom-left-radius: 8px !important;
      //     // padding: 0px 11px !important;
      //     height: 46px !important;
      //     border-top-right-radius: 0px !important;
      //     border-bottom-right-radius: 0px !important;
      //   }
      //   .ant-input-group-addon {
      //     height: 46px !important;
      //   }
      // }
      span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        height: 46px;
      }
      .ant-input-group-addon {
        height: 46px !important;
        vertical-align: top !important;
      }

      .ant-btn.ant-btn-default.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
        border-radius: 0 8px 8px 0 !important;
        height: 46px !important;
        padding: 0px !important;
        vertical-align: 0px !important;
      }
    }

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 42px;
    }
  }

  .coding-school-Table-container {
    margin: 40px 20px;
    background-color: #ffffff;
  }

  .pagination-container {
    padding-bottom: 30px;

    .ant-pagination-item {
      border: 1px solid #f2f2f2 !important;
      border-radius: 3px !important;
      background-color: #f2f2f2 !important;
      color: #000000 !important;

      a {
        color: #000000 !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        padding-top: 5px !important;
      }
    }

    .ant-pagination-item-active {
      border: 1px solid #5fa1ff !important;
      border-radius: 3px !important;
      background-color: #5fa1ff !important;
      color: #f2f9fe !important;

      a {
        color: #f2f9fe !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        padding-top: 5px !important;
      }
    }

    .ant-pagination-item-link {
      border: 0 !important;
      background-color: #f2f2f2 !important;
    }
  }
}

.school-list-loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove {
  text-align: center !important;
}

.add-coding-schol-modal {
  .ant-modal-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #2b323b;
  }

  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #656c7c;
  }
  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }

  .ant-modal-content {
    border-radius: 8px;
  }

  .input-phone {
    width: 100%;
    border: 1px solid #c3c6cd;
    border-radius: 8px;
    height: 40px;
    padding: 0px 11px;
  }

  .disabled {
    color: rgba(0, 0, 0, 0.6);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }

  .input-phone::selection {
    border: 1px solid #1890ff !important;
  }

  .input-phone:focus {
    border: 1px solid #46a3fa !important;
    outline: 0px !important;
    box-shadow: 0 0 1pt 1pt #bbdbfa;
    padding: 0px 11px;
  }
}
.program-table {
  margin-top: 15px;
  margin-bottom: 20px;

  overflow: auto;
  .ant-table-body {
    overflow: auto !important;
  }
}
.advance-rate-table {
  .ant-table-cell {
    text-align: center;
  }
}

.table-action-buttons {
  gap: 10px;
  justify-content: start;
  .edit-icon {
    display: flex;
    align-items: center;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.get-Hover:hover {
  cursor: pointer !important;
}
.coding-school-landing-edit-icon {
  color: blue;
  cursor: pointer;
  margin-left: 12px;
}
.coding-school-landing-delete-icon {
  color: red;
  cursor: pointer;
}
.coding-school-landing-upload-button {
  margin-left: 12px;
}

.coding-school-landing-search-input {
  width: 30%;
  margin: 16px;
  max-width: 320px;
  min-width: 260px;

  span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    height: 46px;
  }
  .ant-input-group-addon {
    height: 46px !important;
    vertical-align: top !important;
  }

  .ant-btn.ant-btn-default.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
    border-radius: 0 8px 8px 0 !important;
    height: 46px !important;
    padding: 0px !important;
    vertical-align: 0px !important;
  }
}
.landing-detail-table {
  margin-top: 16px;
  margin-bottom: 16px;
  overflow-x: scroll;
}
