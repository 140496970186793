.application-list-filters-cover {
    .application-list-filter {

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #656C7C;
        border-radius: 8px !important;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #656C7C;
        gap: 10px;

    }

    .ant-btn-lg {
        height: fit-content
    }

    .selected-stage-name {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #656C7C;
        margin-left: 3px;
    }

    .selected-bars {
        display: flex;
        align-items: center;
        gap: 1px;
    }

    .pending-bar {
        border-left: 2px solid #FF845E;
        height: 18px;
    }

    .expired-bar {
        border-left: 2px solid #656C7C;
        height: 18px;
    }

    .failed-bar {
        border-left: 2px solid #F22D55;
        height: 18px;
    }

    .cancelled-by-student-bar {
        border-left: 2px solid #2F74D3;
        height: 18px;
    }

    .cancelled-by-admin-bar {
        border-left: 2px solid #9E00BE;
        height: 18px;
    }

    .cancelled-by-onelogos-bar {
        border-left: 2px solid #15B0C6;
        height: 18px;
    }

    .funded-bar {
        border-left: 2px solid #25A050;
        height: 18px;
    }
}

.status-tooltip {
    padding: 10px 0 0px 0 !important;

    .ant-tooltip-inner {
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
        color: #656C7C;
    }
}

.filter-menu-main-div {
    box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
    border-radius: 12px;
    background: #FFFFFF;
    width: fit-content;
    float: right;
}

.student-list-filter-btn-row {
    background: #FFFFFF;
    padding: 15px;
    gap: 10px;
    border-radius: 0 0 12px 12px;
}

.filter-menu-cover {
    padding: 15px;
    overflow: auto;
    max-height: 600px;

    .filter-options-row {
        padding: 8px 0;
        border-bottom: 1px solid #EDF0F5 !important;
        gap: 50px;

        .ant-checkbox-wrapper {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #2B323B;
            gap: 10px;
        }

        .status-icons-btn-row {
            button {
                background: none;
                border-radius: 0px;
                border: none;
                padding: 0 !important;
                opacity: 0.5;
            }

            .selected-state {
                opacity: 1 !important;
            }

            button:disabled {
                opacity: 0.3 !important;
                // visibility: hidden;
            }

            .count {
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                text-align: right;
                color: #656C7C;
                padding-left: 15px;
                display: flex;
                align-items: center;
                height: 100%;
                justify-content: flex-end;
            }
        }
    }

}