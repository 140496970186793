.admin-dash-term-header {
  .ant-page-header-heading-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    color: #242a32;
  }

  .ant-btn-circle {
    font-size: 27px;
    color: #367bda !important;
  }

  .ant-page-header-heading-left {
    align-items: unset !important;
  }
}

.terms-list-section-container {
  margin-top: 34px;
  padding: 0px 40px;
}

.admin-terms-filter {
  border-radius: 30px;
  color: white;
  background: #367bda;
  width: 140px;
}

.admin-terms-table .ant-table-column-sorters {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
}

.search-column-item {
  font-weight: bold;
  font-size: 16px;
  line-height: 27px;
  color: #000000;
}
