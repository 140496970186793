.refund-batch-main-container {
  min-height: 100vh;
  height: 100%;
  background-color: #f7f8fb;
  .loader-continer {
    height: calc(100vh - 200px);
  }
  p {
    margin-bottom: 0px;
  }
  .common-txt {
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-style: normal;
  }
  .refund-batch-header {
    padding: 32px 24px 24px 24px;
    border-bottom: 1px solid #e6eefa;
    background: #fff;
    .back-btn {
      display: flex;
      align-items: center;
    }
    .action-review-error {
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #fff0f0;
      color: #c31033;
      font-feature-settings: "liga" off, "clig" off;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px; /* 162.5% */
    }
    .three-dot-margin {
      margin: 0 0 15px 15px;
    }
    .expired-tag {
      color: #656c7c !important;
      background-color: #ebeff6 !important;
    }
    .justify-end {
      display: flex;
      justify-content: end;
      align-items: center;
    }
    .ml-20 {
      margin-left: 20px;
    }
    .text-end {
      text-align: end;
    }
    .cursor-notallow {
      cursor: not-allowed;
    }
    .hr-line {
      width: 1px;
      height: 53px;
      background: #c3c6cd;
      margin-right: 28px;
      margin-left: 28px;
    }
    .border-none {
      border: none;
    }
    .tag-center {
      gap: 24px;
      height: 100%;
      display: flex;
      align-items: center !important;
    }
    .dot-center {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .back-txt {
      @extend .common-txt;
      color: #2f74d3;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
    }
    .main-heading {
      @extend .common-txt;
      color: #000;
      font-size: 22px;
      font-weight: 700;
      line-height: 32px;
    }
    .gray-txt {
      @extend .common-txt;
      color: #656c7c;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
    .black-text {
      @extend .common-txt;
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .refund-batch-body {
    padding: 16px;
    .page-heading {
      @extend .common-txt;
      color: #000;
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
    }
  }
  .refund-batch-cards {
    padding: 16px 16px 16px 24px;
    border-radius: 8px;
    background: #fff;
    .card-heading {
      @extend .common-txt;
      color: #000;
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
    }
    .amount-heading {
      @extend .common-txt;
      color: #000;
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
    }
    .card-hr-line {
      width: 1px;
      height: 112px;
      background: #ebeff6;
    }
    .gray-txt {
      @extend .common-txt;
      font-size: 12px;
      color: #656c7c;
      font-weight: 400;
      line-height: 16px;
    }
    .black-txt {
      @extend .gray-txt;
      color: #000;
    }
    .card-section2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .section3-parent {
      min-width: 300px;
    }
    .section3-child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 0px;
      border-bottom: 1px solid #ebeff6;
    }
    .card-section5 {
      display: flex;
      min-width: 230px;
      padding: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      border-radius: 8px;
      background: #f5f9fe;
    }

    .txt-left {
      width: 100%;
      text-align: start;
    }
    .txt-right {
      width: 100%;
      text-align: end;
    }
  }
  .nodata-found {
    height: calc(100vh - 350px);
    width: 100%;
    .nodata-heading {
      color: #656c7c;
      text-align: center;
      font-feature-settings: "liga" off, "clig" off;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .nodata-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }
}
.refund-delete-edit-dropdown {
  .ant-dropdown-menu {
    min-width: 150px;
    padding: 10px 10px;
    border-radius: 6px;
    li:hover {
      background-color: transparent;
    }
    h3 {
      color: #000;
      font-feature-settings: "liga" off, "clig" off;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
}
.refunds-batch-submit-modal {
  //to hide number inputbox arrow icons
  /* For Chrome, Safari, Edge, and Opera */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* For Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .red-txt-container {
    margin-top: 16px;
    padding: 8px 16px;
    border-radius: 8px;
    background: #fff0f0;
    color: #c31033;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .notes-txt {
    color: #656c7c;
    font-feature-settings: "liga" off, "clig" off;

    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .ant-modal-header {
    padding: 10px 16px 10px 16px;
    border-radius: 8px 8px 0 0;
  }
  p {
    margin-bottom: 0px;
  }
  .gray-txt {
    color: #656c7c;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .black-text {
    @extend .gray-txt;
    color: #000;
  }
  .header-heading {
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
  .main-heading {
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }
  .sub-heading {
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
  }
  .input-continer {
    border-radius: 8px;
    border: 1px solid #c3c6cd;
    background: #f5f9fe;
    padding: 8px 16px 24px 16px;
  }
  .wire-instruction {
    color: var(--Dark-Grey, #656c7c);
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .wire-instructions-container {
    cursor: pointer;
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #c3c6cd;
    .instruction-bold-txt {
      color: #000;
      font-feature-settings: "liga" off, "clig" off;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
    }
  }
}
