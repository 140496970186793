.overview-container {
  min-height: 100vh;
  background-color: #ffffff;

  .overview-loader {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// header

.student-dashboard-header {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px 20px 0px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.04);

  .heading-col {
    display: flex;
    align-items: center;

    h2 {
      font-weight: 700;
      font-size: 30px;
      line-height: 41px;
      color: #2b323b;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #656c7c;
    padding-bottom: 8px;
    border-bottom: 3px solid #ffffff;
    cursor: pointer;
  }

  .active-tab {
    color: #000000;
    border-bottom: 3px solid #5fa1ff;
  }
}

// filter details

.overview-filter-container {
  width: 96%;
  margin: 20px auto;

  h2 {
    font-style: normal;
    font-weight: 700 !important;
    font-size: 23px;
    line-height: 31px;
    color: #2b323b;
  }

  .text-gray {
    font-size: 14px;
    color: #6c757d;
    margin-top: 10px;
  }

  .edly-blue-button,
  .edly-blue-border-button {
    padding: 5px 64px !important;
  }

  .export-btn {
    display: inline-flex !important;
    width: auto !important;
    margin-right: 20px !important;

    button {
      height: 40px;
      font-weight: 700;
      font-size: 16px !important;
      border: 1px solid #c3c6cd;
    }

    .ant-btn-compact-first-item {
      width: 120px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }

    .ant-btn-compact-last-item {
      width: 40px;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
    }
  }
}

// buckets

.overview-bucket-container {
  width: 96%;
  margin: 30px auto;

  .bucket-col {
    background: #ffffff;
    box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
    border-radius: 12px;

    h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      padding: 15px;
      border-bottom: 1px solid rgba(43, 50, 59, 0.4);
      text-align: center;
    }

    .count {
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 70px;
      cursor: pointer;
      padding: 15px;
      text-align: center;
      color: #fc4444;
      margin: 0;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1593px) {
  .overview-bucket-container {
    .bucket-col {
      h3 {
        min-height: 69px;
      }
    }
  }
}

// student funnel

.student-funnel-container {
  width: 96%;
  margin: 30px auto;
  padding: 20px 15px 15px 30px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.15);

  h4 {
    font-size: 18px !important;
    font-weight: bold !important;
    margin-bottom: 10px !important;
  }

  .funnel-header-row {
    display: flex !important;
    align-items: flex-end !important;
    .student-count {
      font-weight: 700;
      font-size: 42px;
      line-height: 57px;
      color: #000000;
      margin: 0 !important;
      padding: 0 !important;
    }

    .bold-text {
      margin-right: 8px;
      font-weight: 700;
      font-size: 16px;
    }

    .black-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      margin-bottom: 0px !important;
      padding: 2px 0 20px 0;
    }

    .rate-col {
      display: flex;
      align-items: center;
      gap: 52%;
    }
  }
}

// record detail

.record-detail-container {
  padding-top: 30px;

  .record-detail-status {
    display: inline-block;
    overflow: hidden;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
  }
  .record-detail {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 10px 0;
    font-weight: 700;
    font-size: 27px;
    line-height: 32px;

    .record-percentage {
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: #656c7c;
    }
  }

  .detail-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 10px !important;
  }

  .bg-gray {
    background: #f7f8fb;
  }
}

// range filters
.range-filter-detail-container {
  display: flex;
  .filters-container {
    h5 {
      display: inline-block;
      margin-right: 8px;
      font-weight: 600;
      font-size: 16px;
    }

    h5::after {
      content: ', ';
    }

    h5:last-child::after {
      content: '';
    }
  }
}

// stage status filter

.stage-status-filters {
  display: flex;
  align-items: baseline;
  .comma-separated-div-container {
    display: flex;
    gap: 3px;
    margin: 0 0 10px 0;
    overflow-x: scroll;
    .comma-separated-div {
      min-width: 40%;
      span {
        display: inline-block;
        margin-right: 6px;
      }

      span::after {
        content: ', ';
      }

      span:last-child::after {
        content: '';
      }
    }
  }
  .comma-separated-div-container::-webkit-scrollbar {
    height: 5px;
  }

  .comma-separated-div-container::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px black;
    border-radius: 5px;
  }

  .comma-separated-div-container::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 8px;
  }

  .comma-separated-div-container::-webkit-scrollbar-thumb:hover {
    background: rgb(109, 109, 109);
  }
}
