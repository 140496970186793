.overview-drawer {
  .ant-drawer-content-wrapper {
    width: 400px !important;

    .ant-drawer-content {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      .ant-drawer-header {
        border-bottom: none !important;
        padding: 20px 18px 10px 18px !important;
        .ant-drawer-header-title {
          flex-direction: row-reverse;

          .ant-drawer-close {
            margin-right: 0 !important;
            color: #656c7c !important;
            font-size: 24px !important;
          }

          .ant-drawer-title {
            color: var(--Midnight, #2b323b);
            font-size: 18px;
            font-weight: 700;
            line-height: 28px;
          }
        }
      }
      .ant-drawer-body {
        padding: 10px 18px 24px 18px !important;
        .overview-drawer-form {
          .ant-form-item-label {
            label {
              color: rgba(43, 50, 59, 0.74);
              font-size: 18px;
              font-weight: 700;
              line-height: 28px;
            }
          }

          .ant-radio-group {
            label {
              display: block;
              color: var(--Dark-Gray, #656c7c);
              font-size: 14px;
              font-weight: 600;
              line-height: normal;
              margin: 15px 0;
            }
          }

          .filter-divider-div {
            padding: 0;
            .drawer-filter-divider {
              border-top: 2px solid #c3c6cd;
              margin: 0 0 24px 0 !important;
            }
          }

          .drawer-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 20px !important;
          }

          .custom-dates {
            margin-left: 10px;

            .ant-form-item-label {
              label {
                font-size: 14px !important;
              }
            }
            .ant-picker {
              width: 95% !important;
            }
          }
        }
      }
    }
  }
}
