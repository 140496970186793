.admin-stu-detail {
  background: #f2f2f2 !important;
  padding: 25px 35px;

  .stu-detail-doc-tab-section {
    padding: 30px;
  }

  .stu-detail-doc-count {
    color: #2b323b;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
  }

  .stu-detail-doc-tab-head-sec {
    display: flex;
    justify-content: flex-end;

    .ant-input-group {
      .ant-input {
        border-right: none;
        border-radius: 8px 0 0 8px !important;
      }

      .ant-input-group-addon {
        button {
          border: 1px solid #c3c6cd !important;
          border-left: none !important;
          border-radius: 0 8px 8px 0 !important;
          height: 32px !important;
        }
      }
    }
    .btn-mb-5{
      margin-bottom: 5px;
    }
  }

  .stu-detail-docs-card {
    background: #ffffff;
    box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
    border-radius: 16px;
    padding: 36px 56px;
  }

  .admin-stu-detail-collapse-app-head2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #2b323b;
  }

  .stu-detail-doc-card-doc-list {
    border-bottom: 1px solid #e5e9f0;
  }

  .stu-detail-docs-card-doc-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #2b323b;
  }

  .stu-detail-app-collapse-date-txt {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #898d96;
  }

  .student-admin-isa-request-r-text {
    text-align: end;
  }

  .app-collapse-blue-txt {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #0049a4;
    padding-left: 20px;

    .icon-gap {
      padding-left: 4px;
    }
  }

  .doc-download-btn {
    color: #367bda;
    padding-left: 10px;
    font-size: 20px;
  }

  .hidden-download-button {
    visibility: hidden;
  }

  .consent-headings {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
  }

  .consent-sub-headings {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
}
.student-deatils-upload-docs-modal{
  .ant-modal-content{
    border-radius: 10px;
  }
  .ant-modal-header {
    border-radius: 10px 10px 0 0;
    .ant-modal-title {
      font-weight: 700; 
    }
  }
  .ant-form-item-label{
    padding: 0px !important;
  }
}

