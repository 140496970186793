.signed-heading-row {
  align-items: center;
  // min-height: 32px;
  margin: 18px 0px 0px;
  padding-left: 16px;
  .signed-heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #2b323b;
  }
}
.signed-data-main-row {
  align-items: center;
  .signed-data-col {
    min-width: 100%;
    // min-height: 50px;
    padding: 12px 16px 6px 16px !important;
    border-bottom: 1px solid #edf0f5;
    .signed-data-inner-row {
      .doc-name-col {
        display: flex;
        align-items: center;
        gap: 6px;
        .signed-data-label {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #656c7c;
          margin: 0px !important;
        }
      }
      .signed-data-date {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #656c7c;
        margin: 0px !important;
      }
      .doc-status-col {
        display: flex;
        justify-content: flex-end;
      }
      .right-span {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
