.ant-drawer-content{
    box-shadow: 0px 2px 14px rgb(0 0 0 / 15%);
    border-radius: 8px 0px 0px 8px;
}

.ant-drawer-body{
    padding: 0px !important;
    .payment-estimator-wrap{
        .estimator-outer-div {
            background: #f7f8fb;
            padding: 1.5rem;
            .payment-estimation-head-wrp {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 0.5rem;
                .est-heading {
                    font-size: 28px;
                    line-height: 38px;
                    color: #2B323B;
                    font-weight: 700;
                }
                svg{
                    cursor: pointer;
                    color: #656c7c;
                    height: 27px;
                    width: 27px;
                }
            }
            .row-1 {
                margin: 1.5rem 0;
                .inner-heading {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    color: #656c7c;
                }
                .avarage-inpput {
                    background: #ffffff;
                    border-radius: 8px;
                    width: 100%;
                    text-align: center;
                    outline: none;
                    border: none;
                    .ant-select-selection-item{
                        font-size: 14px;
                    }
                    .ant-input-number-input{
                        border: 1px solid #656C7C;
                        text-align: center;
                    }
                    .ant-select-selector{
                        border: 1px solid #656C7C;
                    }
                    .ant-select-arrow{
                        color: #2F74D3;
                    }
                }
            }
        }
        .row-2 {
            margin: 2rem;
            h3.row-2-head {
              font-weight: 600;
              font-size: 14px;
              line-height: 24px;
              text-align: right;
              color: #000000;
            }
            .ant-divider-horizontal {
              margin: 6px 0;
            }
            h3.row-2-dics {
              font-weight: 600;
              font-size: 14px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: #656c7c;
            }
            h3.total-loan {
              font-weight: 700;
              font-size: 22px;
              line-height: 32px;
              text-align: right;
            }
            .row-2-blue {
              color: #2f74d3;
            }
            .row-2-light-red {
              color: #ff845e;
            }
            .apr-per {
              font-weight: 600;
              font-size: 16px;
              line-height: 26px;
              text-align: right;
            }
        }
        .payment-breakdown-head {
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
            color: #2b323b;
            margin: 2.5rem 2rem 0.5rem;
          }
        .row-3 {
            margin: 1rem 2rem 2rem;
        
            .row-payment-head {
              font-weight: 600;
              font-size: 14px;
              line-height: 24px;
              text-align: center;
              color: #656c7c;
            }
            .left-text{
                text-align: left;
            }
            .payment-breaker-column {
              padding: 12px 16px;
              border-top: 1px solid #edf0f5;
              .py-col {
                text-align: center;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: #2b323b; 
              }
            }
            .sky-white-blue-bg {
              background: #f2f9fe;
            }
          }
    }
}

