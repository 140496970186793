.requested-terms-card {
  padding-top: 12px;
  .requested-terms-heading {
    // min-height: 48px;
    align-items: center;
    padding: 0px 12px !important;
    .terms-heading-name {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      color: #2b323b;
    }
  }
  .requested-terms-amount-row {
    // min-height: 48px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px !important;
    .terms-amount-value {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      color: #2f74d3;
    }
    .terms-requested-date {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #656c7c;
      // padding-right: 2%;
    }
  }
  .card-rows-divider {
    margin: 0px !important;
  }
  .terms-details-data {
    justify-content: space-between;
    align-items: center;
    .requested-details-data {
      // min-height: 50px;
      padding: 12px 16px 2px 16px !important;
    }
    .modified-details-data {
      min-height: 50px;
      padding: 15px 16px 0px 16px !important;
      border-bottom: 1px solid #edf0f5;
    }
    .inner-row {
      justify-content: space-between;
    }
    .right-span {
      display: flex;
      justify-content: flex-end;
    }
    .term-details-label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #656c7c;
    }
    .term-details-edit-date {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #656c7c;
    }
    .term-details-value {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
  }

  .corner-padding {
    padding: 0 16px;
  }
}
