.admin-detail-container {
    .header {
        padding: 20px;

        h2 {
            font-weight: 700;
            font-size: 30px;
            line-height: 41px;
            color: #2B323B;
        }
    }

    .admin-detail-body-container {
        background: #f0f2f5;
        padding: 30px 20px;

        .admin-detail-body {
            box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
            border-radius: 12px;
            background-color: #FFFFFF;

            .admin-name-row {
                border-bottom: 1px solid #DFE1E6;
                padding: 20px;

                h2 {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 44px;
                    color: #242A32;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                }

                h3 {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    color: #2F74D3;
                    padding-right: 40px;
                    cursor: pointer;
                }
            }

            .basic-info-row {
                border-bottom: 1px solid #DFE1E6;
                padding: 20px 60px 20px 20px;

                h3 {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 25px;
                    color: #242A32;
                }

                h4 {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    color: #656C7C;
                    padding: 15px 0 30px;
                }
            }

            .admin-roles-row {
                padding: 20px;

                h2 {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 25px;
                    color: #242A32;
                }

                .ant-select {
                    width: 100%;
                    max-width: 900px;
                }
            }
        }
    }
}

.remove-admin-comform-modal {
    .ant-modal-header {
        border-radius: 8px;
    }

    .ant-modal-content {
        border-radius: 8px;
    }

    .ant-modal-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #2B323B;
    }

    .ant-modal-close-x {
        height: 100%;

        svg {
            font-size: 22px;
            font-weight: 800;
        }
    }

    h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        color: #2B323B;
        padding: 20px;
    }
    .btn-row{
        border-top: 1px solid #EDF0F5;
        padding: 10px 20px;
    }
    .ant-modal-body{
        padding: 0;
    }
    img{
        margin-top: 30px;
    }
}