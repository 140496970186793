.funding-investor-container {

    .header {
        padding: 15px 20px;

        h2 {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #2B323B;
        }
    }

    .investor-name-row {
        border-bottom: 1px solid #EDF0F5;

        h2 {
            padding: 0 20px 15px;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #656C7C;


        }

        h3 {
            padding: 0 20px 15px;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #000000;
            display: flex;
            align-items: center;
            gap: 10px;

            span {
                background: #F2F9FE;
                border-radius: 4px;
                padding: 3px 5px;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                color: #2F74D3;
                cursor: pointer;
            }
        }

        h4 {
            padding: 0 20px 15px;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            color: #0671FF;
            cursor: pointer;
        }
    }

    .investory-history-row {
        padding: 20px;
        background: #F7F8FB;

        .ant-table-cell::before {
            border: none !important;
            width: 0px !important;
        }

        table {
            box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
        }

        th {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: #656C7C;
        }

        td {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #2B323B;
        }
    }

    .assign-investor-form {
        padding: 15px;
        margin: 10px;
        background: #F7F8FB;
        border-radius: 12px;

        h2 {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #2B323B;
        }
    }
}

.funding-investor-modal {

    h2 {
        font-size: 14px;
        line-height: 25px;

        span {
            font-weight: 600;
        }
    }

    .ant-modal-content {
        border-radius: 12px;
    }

    .ant-modal-header {
        border-radius: 12px;
    }

    .ant-modal-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #2B323B;
    }

    .funding-investor-form .reason-row {
        background: #f2f9fe;
        border-radius: 8px;
        margin: 15px 0;
    }

    .funding-investor-form .option {
        border-radius: 8px;
        text-align: center;
        width: 100%;
        padding: 10px 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        cursor: pointer;
    }

    .funding-investor-form .selected-option {
        background: #0049a4;
        color: #ffffff;
    }

    .funding-investor-form .w-100 {
        width: 100%;
    }

    .funding-investor-form .border-radius {
        border-radius: 8px;
    }

    .ant-input-number {
        border-radius: 8px;
    }

    .ant-picker {
        border-radius: 8px;
    }

    .ant-form-item {
        margin-bottom: 15px;
    }
}