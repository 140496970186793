.range-drawer {
  .ant-drawer-content-wrapper {
    width: 500px !important;
  }

  .ant-drawer-header {
    padding: 20px 22px 20px 45px !important;
  }

  .ant-drawer-title {
    font-weight: 700 !important;
    font-size: 28px !important;
    line-height: 32px !important;
    color: #242a32 !important;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer-body {
    padding: 15px 22px;
  }

  .range-drawer-form {
    .ant-form-item-label {
      label {
        font-weight: 700 !important;
        font-size: 18px !important;
        line-height: 28px !important;
        padding-bottom: 12px;
        color: #2b323b !important;
      }
    }

    .form-items {
      margin-left: 45px;
      margin-right: 45px;
      margin-top: 24px;

      .radio-buttons-group {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }

    .custom-dates {
      margin-left: 45px !important;

      .ant-form-item-label {
        label {
          font-weight: 600 !important;
          font-size: 14px !important;
          line-height: 24px !important;
          padding-bottom: 0 !important;
          color: #656c7c !important;
        }
      }
    }

    .drawer-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px !important;
      padding: 0 20px 40px 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .range-drawer {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}
