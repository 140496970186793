.assign-investor-header {
  padding: 24px 16px 0px 16px;

  p {
    margin: 0;
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    color: #2b323b;
  }
}

.assign-investor-header-tab {
  .ant-tabs-nav-wrap {
    padding: 24px 24px 0px 24px;
  }

  //   .ant-tabs-content-holder {
  //     padding: 36px;
  //   }
}

.edly-student-certification-approved,
.edly-student-certification-confirm {
  margin: 36px;
}

.edly-student-certification-approved-tbl-search {
  width: 25%;
}

.edly-student-certification-approved-search-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  font-size: 18px;
}

.edly-student-certification-confirm-search-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
}

.edly-student-certification-confirm-search-sec .search-btn {
  display: flex;
  align-items: center;
}

.edly-student-certification-confirm-search-sec .cancel-btn,
.bulk-inv-cancel-btn {
  height: 38px;
  border: 1px solid #0049a4;
  border-radius: 100px;
  color: #0049a4;
  font-weight: 700;
  font-size: 12px;
  padding: 8px 24px;
  line-height: 22px;
  margin: 0px 16px;
}

.edly-student-certification-confirm-search-sec .confirm-btn,
.bulk-inv-assign-btn {
  height: 38px;
  background: #2f74d3;
  border-radius: 100px;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  padding: 8px 19px;
}

.edly-student-certification-confirm-search-sec .confirm-btn,
.bulk-inv-assign-btn:hover {
  background: #2f74d3;
  color: #ffffff;
}

.bulk-inv-assign-btn-font-16 {
  font-size: 16px !important;
}

.edly-student-certification-confirm-tbl-search,
.edly-student-certification-approved-tbl-search {
  border-radius: 8px;
  width: 196px;
  height: 42px;
}

.removeRed {
  background-color: #f22d55;
  border: none;
}

.edly-student-certification-confirm-search-sec .confirm-back-sec-btn {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.edly-student-certification-confirm-search-sec
  .confirm-back-sec-btn
  .confirm-back-sec-back-btn {
  margin-right: 4px;
  cursor: pointer;
}

.normal-remove,
.red-remove {
  cursor: pointer;
}

.student-name-bulk-list {
  color: #2f74d3;
  font-weight: 400;
  font-size: 14px;
}

.student-ibr-id-bulk-list {
  color: #0049a4;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  background: #f2f9fe;
  border-radius: 4px;
  padding: 1px 8px;
  max-width: 265px;
}

.confirm-back-sec-btn span {
  margin: 0px 4px;
}

.inv-exp-table-data {
  display: flex;
}

.inv-exp-table-data-colon {
  margin: 0 4px;
}

.bulk-inv-select-table .ant-table-thead th,
.bulk-inv-select-table .ant-table-thead .ant-table-column-sorters span {
  color: #656c7c !important;
  font-weight: 700 !important;
  font-size: 12px !important;
}
