.credit-report-modal {
    width: 80% !important;
    .ant-modal-body {
        min-height: 600px;
      }
      .credit_report_section {
        border-bottom: 2px dashed rgb(0 0 0 / 19%);
        padding: 10px 0 15px 0;
        .ant-space.ant-space-horizontal {
            flex-wrap: wrap;
          }
          .table-responsive {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
        }
        .table {
            --bs-table-bg: transparent;
            --bs-table-striped-color: #212529;
            --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
            --bs-table-active-color: #212529;
            --bs-table-active-bg: rgba(0, 0, 0, 0.1);
            --bs-table-hover-color: #212529;
            --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
            width: 100%;
            margin-bottom: 1rem;
            color: #212529;
            vertical-align: top;
            border-color: #dee2e6;
        }
        .table>thead {
            vertical-align: bottom;
        }
        .table>:not(:last-child)>:last-child>* {
            border-bottom: 1px solid black;
        }
        .table>:not(caption)>*>* {
            padding: 0.5rem 0.5rem;
            background-color: var(--bs-table-bg);
            box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
        }
      }
  }

  