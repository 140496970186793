.admin-stu-detail-servicer {
  background: #ffffff !important;
  padding: 12px 12px;
  /* height: 75vh; */
}
.admin-stu-detail-servicer .stu-detail-overview-section {
  padding: 16px;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
}
.admin-stu-detail-servicer
  .stu-detail-overview-section
  .col-servicer-graph-inner-container {
  display: flex;
  flex-direction: column;

  .filters {
    display: flex;
    gap: 10px;

    .ant-picker-clear {
      .anticon-close-circle {
        position: absolute;
        right: 0px !important;
        bottom: -10px !important;
      }
    }
    .ant-select-clear {
      .anticon-close-circle {
        position: absolute;
        right: 0px !important;
      }
    }
  }

  .col-servicer-graph-inner-container-heading {
    font-weight: 700;
    font-size: 12px;
    line-height: 28px;
    color: #000000;
  }

  .col-servicer-graph-inner-container-amount {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
  }
}
.col-servicer-graph-inner-container span {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 42px;
}
.col-servicer-graph-inner-container .ant-picker {
  border: 0px !important;
  width: 20%;
}
.col-servicer-graph-inner-container .ant-picker-focused {
  box-shadow: 0px !important;
}
.admin-stu-detail-servicer h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #2b323b;
  padding-bottom: 10px;
}

.admin-stu-detail-servicer .field-headings {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #656c7c;
  padding: 10px 0px 5px 0px;
}
.admin-stu-detail-servicer .field-values {
  padding: 10px 0px 5px 0px;
}
.admin-stu-detail-servicer p {
  text-align: right;
  padding-right: 25px;
}
.admin-stu-detail-servicer .table-heading-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.admin-stu-detail-servicer .table-heading-container .ant-picker {
  border: 0px !important;
}
.admin-stu-detail-servicer .ant-picker-input > input {
  color: #0049a4 !important;
}
.admin-stu-detail-servicer .ant-picker-input input::placeholder {
  color: #0049a4 !important;
}

.admin-stu-detail-servicer .badge-green {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background: #e4faec;
  border-radius: 4px;
  padding: 10px;
  color: #25a050;
}
.admin-stu-detail-servicer .badge-red {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background: #fde5ea;
  border-radius: 4px;
  padding: 10px;
  color: #f22d55;
}
.admin-stu-detail-servicer .badge-yellow {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background: #fff9dc;
  border-radius: 4px;
  padding: 10px;
  color: #ff845e;
}
.admin-stu-detail-servicer img:hover {
  cursor: pointer;
}

.admin-stu-detail-servicer {
  .table-heading {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #656c7c;
  }
}

.stu-detail-overview-section {
  .ant-table-tbody {
    td {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
  }
}

.distribution-total {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.distribution-total-amount {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding: 0 20px;
}

.admin-stu-detail-servicer .border-bottom {
  border-bottom: 1px solid #edf0f5;
}
