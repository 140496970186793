.refund-complete-main-container {
  min-height: 100vh;
  height: 100%;
  background-color: #f7f8fb;
  .nodata-found {
    color: #656c7c;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .refund-filter-body {
    padding: 20px 30px 0px 30px;
  }
  .refund-body {
    padding: 30px 20px;
  }
  .dashboard-loader {
    height: 90vh;
  }
  .section-header-txt {
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
  }
}
