.missing-record-continer {
  background-color: #ffffff;
  .missing-record-header {
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.04);

    h2 {
      padding: 16px 24px;
      color: #2b323b;
      font-size: 30px;
      font-weight: 700;
    }
  }

  .missing-record-spin {
    position: absolute !important;
    top: 50% !important;
  }

  .missing-record-body {
    margin: 20px 30px 0 30px;
    border: 1px solid #edf0f5;
    border-radius: 8px;
    background-color: #f7f8fb;
    padding-bottom: 20px;
    .missing-records-detail {
      background-color: #ffffff;
      padding: 30px 20px;
      h2 {
        color: #2b323b;
        font-size: 20px;
        font-weight: 700;
      }

      .missing-count {
        background-color: #fff9dc;
        padding: 12px 16px;
        p {
          color: #2b323b;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin: 0 !important;
          display: flex;
          align-items: center;
        }
      }
    }
    .ant-table-content {
      padding: 0 20px;
    }
    .ant-table-thead {
      tr {
        th {
          background-color: #ffffff;
          border-bottom: none;
          span {
            color: #656c7c;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
          }
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          color: #000000;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    .edit-container {
      h2 {
        color: #2b323b;
        font-size: 16px;
        font-weight: 700;
        padding: 10px;
      }

      .edit-detail {
        background-color: #ffffff;
        padding: 20px 0;

        h6 {
          color: #656c7c;
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.missing-record-modals {
  .ant-modal-title {
    color: #2b323b;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }

  h2 {
    color: #2b323b;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    border-bottom: 1px solid #edf0f5;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .ant-modal-close-x {
    font-size: 24px !important;
  }

  .ant-modal-content {
    border-radius: 8px !important;

    .ant-modal-header {
      border-radius: 8px 8px 0 0 !important;
    }
  }

  .input-number {
    width: 100%;
    border: 1px solid #c3c6cd;
    border-radius: 8px;
    height: 40px;
    padding-left: 7px;
  }

  .input-number::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding-left: 0px;
    color: #cecece;
  }

  .input-number::selection {
    border: 1px solid #1890ff !important;
  }

  .input-number:focus {
    border: 1px solid #46a3fa !important;
    outline: 0px !important;
    box-shadow: 0 0 1pt 1pt #bbdbfa;
  }
}
