.approve-import-continer {
  background-color: #ffffff;
  .approve-import-header {
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.04);

    h2 {
      padding: 16px 24px;
      color: #2b323b;
      font-size: 30px;
      font-weight: 700;
    }
  }

  .approve-import-body {
    padding: 40px 24px 30px 24px;

    .top-row {
      padding-bottom: 30px;
      h3 {
        color: #242a32;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
      }
    }
    .table-cols {
      color: #656c7c;
      font-size: 12px;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.loader-over {
  position: absolute !important;
  top: 50% !important;
  z-index: 100;
}
