.student-admin-dashboard .ant-layout-sider {
  background: #242a33 !important;
  min-height: 100vh;
  /* flex: 0 0 160px !important; */
  /* max-width: 160px !important; */
  /* min-width: 160px !important; */
  /* width: 160px !important; */
}

.student-admin-dashboard .admin-main-layout-content {
  min-height: 100vh;
  background: #f2f2f2 !important;
}

.student-admin-dashboard .ant-menu.ant-menu-dark,
.student-admin-dashboard .ant-menu-dark .ant-menu-sub,
.student-admin-dashboard .ant-menu.ant-menu-dark .ant-menu-sub {
  color: #f7f7f7 !important;
  background: #242a33 !important;
}

.ant-menu-item.ant-menu-item-only-child {
  padding-left: 48px !important;
  color: #f7f7f7 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px !important;
}

.student-admin-dashboard .ant-menu-dark .ant-menu-item:focus,
.student-admin-dashboard .ant-menu-dark .ant-menu-item-group-title:focus,
.student-admin-dashboard .ant-menu-dark .ant-menu-item > a:focus,
.student-admin-dashboard .ant-menu-dark .ant-menu-item > span > a:focus {
  color: #a8ccff !important;
}

/* .student-admin-dashboard .ant-menu-dark .ant-menu-item {
    text-align: center;
    padding: 0 !important;
  } */

.student-admin-dashboard
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: #2e353e !important;
  border-right: solid #4592fb;
}

.student-admin-dashboard .ant-menu.ant-menu-dark .ant-menu-item-selected,
.student-admin-dashboard
  .ant-menu-submenu-popup.ant-menu-dark
  .ant-menu-item-selected {
  background-color: #2e353e !important;
}

.student-admin-dashboard .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px !important;
}

.student-admin-dashboard #components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.ant-menu-item {
  padding-left: 16px !important;
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px !important;
  color: #ffffff !important;
  .anticon.anticon-code.ant-menu-item-icon {
    padding-right: 8px;
    height: 16px;
  }
}

.student-admin-dashboard .site-layout-sub-header-background {
  background: #fff;
}

.student-admin-dashboard .site-layout-background {
  height: 100vh;
}

.student-admin-dashboard .sidebar-head {
  margin-top: 24px;
  margin-left: 23px;
  margin-bottom: 32px;
}

.student-admin-dashboard .sidebar-head p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #5fa1ff;
}

.student-admin-dashboard .sidebar-head img {
  height: 23.75px;
  width: 49px;
}

.student-admin-dashboard .ant-menu-submenu-title {
  padding-left: 16px !important;
}

.student-admin-dashboard .ant-menu-submenu-title span {
  vertical-align: middle;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
}

.student-admin-dashboard .ant-menu-submenu-title i {
  margin-top: 6px;
}

.student-admin-dashboard .ant-layout-sider-zero-width-trigger {
  top: 12px;
  z-index: 9;
}

.stu-admin-bottom-user-div {
  background: #2b323b;
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 8px 12px;

  .logout-dropdown {
    display: flex;
  }
}

.stu-admin-user-sider-avatar {
  font-weight: bold;
  font-size: 12px !important;
  line-height: 16px !important;
  text-align: center !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;

  border: 0.5px solid #f22d55;
  color: #f22d55 !important;
  background: #feeaee !important;
}
.stu-admin-sider-user-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}

.stu-admin-sider-username {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
}

.stu-sider-user-menu-icon {
  font-size: 12px;
  color: #ffffff;
}

.adminstab {
  color: #ffffff;
}

.admin-dashboard-sidebar .ant-menu-item.ant-menu-item-only-child {
  display: flex !important;
  align-items: center !important;
}

.admin-dashboard-sidebar {
  .ant-layout-sider-children {
    position: fixed !important;
    background: #242a33 !important;
    width: inherit !important;
  }

  .scroll-bar {
    height: 80%;
    overflow-y: auto;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #242a33;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
