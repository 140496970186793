.refund-expired-main-container {
  min-height: 100vh;
  height: 100%;
  background-color: #f7f8fb;
  .refund-body {
    padding: 30px 20px;
  }
  .section-header-txt {
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
  }
}
