.or-divider{
  color: #656c7c !important;
  border-top-color: #c3c6cd !important;
}

.auth-admin-section {
  /* margin: 55px 0; */
  background-color: #e9f8fe;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.auth-admin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.auth-admin-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 10px;
  width: 50%;
  margin: auto;
  padding: 45px 95px;
}

.auth-form {
  margin-top: 55px;
}

.admin-auth-form-button {
  text-align: center;
}

.admin-auth-form-button button {
  padding: 0px 65px !important;
  border-radius: 22px;
}

.admin-auth-box-text {
  // font: normal normal 500 20px/27px Open Sans;
  color: #242a32;
}

.admin-auth-button-icon {
  padding: 25px 0;
}

.admin-auth-button-icon img {
  width: 32px;
  margin-right: 16px;
}

.admin-auth-button-icon .outlook-icon-btn {
  border: solid 1px #1976d2;
  border-radius: 22px;
  padding: 0px 25px;
  display: flex;
  align-items: center;
}

.admin-auth-button-icon .gmail-icon-btn {
  border: solid 1px #f14336;
  border-radius: 22px;
  padding: 0px 25px;
  display: flex;
  align-items: center;
}

.admin-auth-button-icon .outlook-icon-btn:hover {
  border: solid 1px #1976d2;
  color: rgba(0, 0, 0, 0.85);
}

.admin-auth-button-icon .gmail-icon-btn:hover {
  border: solid 1px #f14336;
  color: rgba(0, 0, 0, 0.85);
}

.admin-auth-otp-input input {
  height: 3.5em;
  width: 3.5em !important;
  margin: 10px 5px 0 5px;
  border: none;
  border-bottom: 1px solid #367bda;
  background-color: #f2f9fe;
}

.admin-auth-form-button {
  border-radius: 20px !important;
  padding: 0px 55px !important;
}

.admin-auth-form-button button {
  background: #3b7edb !important;
  border-color: #3b7edb !important;
}

.admin-auth-goback {
  margin-left: -65px;
}

@media only screen and (max-width: 1400px) and (min-width: 1023px) {
  .auth-admin-box {
    padding: 45px 70px;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .auth-admin-box {
    padding: 45px 8px;
  }
  .resp-auth-go-back-login {
    position: relative;
    padding: 0;
    z-index: 1;
    left: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .auth-admin-box {
    margin: 0px;
    padding: 45px 8px;
    width: 100%;
  }
  .auth-admin-section {
    text-align: center;

    height: 100vh;
  }
  .gmail-icon-btn {
    margin-bottom: 12px;
  }
  .resp-auth-go-back-login {
    display: flex;
    justify-content: flex-start;
    margin: auto;
  }
}

@media only screen and (max-width: 400px) {
  .admin-auth-otp-input input {
    height: 2.5em;
    width: 2.5em !important;
    margin: 10px 5px 0 5px;
    border: none;
    border-bottom: 1px solid #367bda;
    background-color: #f2f9fe;
  }
  .otp-user-email {
    font: normal normal 500 16px/27px Open Sans;
  }
}

.logo-login {
  text-align: center;
}

.auth-admin-container .forgot-password-item {
  text-align: right;
}

.otp-inputs {
  input {
    width: 50px;
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    margin: 3px;
    background: transparent;
    border: none;
    border-bottom: 1px solid #367bda;
    background-color: #f2f9fe;
    text-align: center;
    height: 48px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
