.main-layout {
  background: rgba(0, 0, 0, 0.03);
  margin: 0px;
  .approve-alert-row {
    // margin-top: 16px;
  }

  .ant-card {
    border-radius: 12px;
    box-shadow: 0px 2px 13px rgb(0 0 0 / 15%);
    margin-top: 12px;

    .ant-card-body {
      padding: 16px;
    }
  }

  .personal-card-row {
    margin-bottom: 20px;
  }

  .application-card-col {
    margin-bottom: 24px !important;
  }
}

@media screen and (max-width: 767px) {
  .main-layout {
    .approve-alert-row {
      .approve-card-col {
        .approve-card {
          margin-left: 4%;
          margin-right: 4%;
        }
      }
      .alert-card-col {
        .alert-card {
          margin-left: 4%;
          margin-right: 4%;
        }
      }
    }
  }
}
