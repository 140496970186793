.application-card{
    .ant-card-body{
        padding: 12px 0px 0px !important;
    }
    .heading-row {
        justify-content: space-between;
        align-items: baseline;
        padding: 0px 12px !important;
        .heading-name {
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            color: #2B323B;
        }
    }
    .heading-divider {
        margin-top: 12px !important;
        margin-bottom: 0px !important;
        color: #EDF0F5 !important;
    }
    .terms-below-divider {
        margin: 0px !important;
        color: #EDF0F5 !important;
    }
}