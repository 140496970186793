.school-page-blue-button {
  border: 2px solid #367bda !important;
  border-radius: 25px !important;
  padding: 5px 24px !important;
  color: #ffffff !important;
  font-weight: bold !important;
  min-height: 40px;
  height: 100% !important;
  background-color: #367bda !important;
  transition: all 0.3s;
  cursor: pointer;
}

.blue-highlight {
  color: blue;
}

.blue-highlight:hover {
  cursor: pointer;
}

.school-page-blue-button:hover {
  background: #ffffff !important;
  border: 2px solid #367bda !important;
  color: #367bda !important;
}

.school-page-blue-button:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
  text-shadow: none;
  box-shadow: none;
  opacity: 0.7;
  cursor: not-allowed;
}

.w-100 {
  width: 100% !important;
}

.school-page-blue-border-button {
  border: 2px solid #367bda !important;
  border-radius: 25px !important;
  margin-top: 10px;
  padding: 0px 24px !important;
  color: #367bda !important;
  font-weight: bold !important;
  min-height: 24px;
  height: 100% !important;
  background-color: #ffffff !important;
  transition: all 0.3s;
  cursor: pointer;
}

.school-page-blue-border-button:hover {
  background: #367bda !important;
  border: 2px solid #367bda !important;
  color: #ffffff !important;
}

.school-page-blue-border-button:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
  text-shadow: none;
  box-shadow: none;
  opacity: 0.7;
  cursor: not-allowed;
}

.school-list-main-div .header-row {
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.04);
  padding: 20px;
}

.school-list-main-div .header-row h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
}

.school-list-main-div .header-row .button-large {
  padding: 12px 24px !important;
}

.school-list-main-div .count-row {
  padding: 20px;
}

.school-list-main-div .count-row h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.school-list-main-div .count-row h2 span {
  font-weight: 600 !important;
}

.school-list-main-div .count-row .school-page-blue-border-button svg {
  font-size: 1.5rem !important;
}

.school-list-main-div .table-row {
  padding: 0 20px;
}

.school-list-main-div .schol-list-table table th {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #656c7c;
}

.school-list-main-div .schol-list-table table .centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.school-list-main-div .schol-list-table table td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.school-list-main-div .schol-list-table table td .success {
  background: #e4faec;
  border-radius: 5px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #25a050;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.school-list-main-div .schol-list-table table td .cancelled {
  background: #fef2f4;
  border-radius: 5px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #f22d55;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.school-list-main-div .schol-list-table table .centered {
  .icon svg {
    font-size: 1.5rem !important;
  }
  .green-tick {
    color: #25a050 !important;
  }
  .red-mark {
    color: #f22d55 !important;
  }
}

.school-list-main-div .ant-input {
  border-radius: 8px;
}

.schol-search-input {
  border-radius: 8px;

  button {
    display: none;
  }

  .ant-input {
    border-right: 2px solid #c3c6cd !important;
  }
}

.school-filter-main-div .school-list-filter {
  padding: 0 20px;
  border-radius: 8px !important;
}

.school-filter-main-div .school-list-filter .ant-checkbox-group {
  width: 100%;
}

.school-filter-main-div .filter-checkbox-row {
  padding: 10px 0px;
  border-bottom: 1px solid #edf0f5;
  width: 100% !important;
}

.school-filter-main-div .checkbox-label-badge {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #2f74d3;
  background: #f2f9fe;
  border-radius: 4px;
  padding: 3px 8px;
}

.ant-table-filter-dropdown {
  border-radius: 8px !important;
}

.school-filter-main-div {
  min-width: 250px;
}

.school-filter-main-div .filer-btn-row {
  padding: 10px;
  border-top: 1px solid #edf0f5;
}

.school-detail-main-container {
  /* max-width: 1440px; */
}
.admin-dash-content-header {
  box-shadow: 0px 0px 0px #00000023;
  opacity: 1;
  z-index: 1;
  background: #ffffff !important;
  padding: 20px 50px 20px !important;
  .ant-page-header-heading {
    .ant-page-header-heading-title {
      font-size: 30px !important;
      font-weight: 700 !important;
      line-height: normal !important;
    }
  }
}
.school-detail-main-container .school-detail-search-container {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-right: 20px;
}
.school-detail-main-container .school-detail-collapse {
  padding: 0px 12px;
}
.school-detail-collapse .school-detail-collapse-data {
  display: flex;
  justify-content: space-between;
}
.school-detail-main-container.stu-details-terms-collapse-title {
  font-weight: bold;
  font-size: 22px;
  color: #2b323b;
  margin-bottom: 0px;
}

.school-detail-main-container .cards-outer-div {
  margin-bottom: 20px;
}
.school-detail-main-container {
  .school-main-details-card {
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 21px 24px 0px !important;
    .ant-card-body {
      padding: 0px !important;
    }
    .school-details-heading-row {
      align-items: center;
      .heading-internal-row {
        align-items: center;
        .school-name {
          font-weight: 700;
          font-size: 20px;
          line-height: normal;
          color: #2b323b;
        }
        .stu-details-terms-collapse-title-ope-id {
          font-style: normal;
          font-weight: 700 !important;
          font-size: 14px !important;
          line-height: 24px !important;
          background-color: #f2f9fe;
          border-radius: 4px;
          margin-left: 20px;
          padding: 4px 8px;
          .stu-details-terms-ope-id {
            color: #0049a4;
          }
        }
      }
      .icons-internal-row {
        justify-content: space-between;
        align-items: center;
        span.anticon {
          height: 25px;
          width: 25px;
          font-size: 20px;
        }
        .active-program-switch-text {
          margin-right: 6px;

          font-style: normal;
          font-weight: 700 !important;
          font-size: 14px !important;
          line-height: 24px !important;
        }
      }
    }
    .school-details-row-outer {
      margin-top: 8px;
    }
    .collapse-adjust {
      margin-top: 25px;
    }
  }
}
.school-detail-main-container .school-detail-sub-container {
  max-width: 1748px;
  margin-left: 40px;
  margin-right: 40px;
}
.school-detail-sub-container .school-detail-sub-container-row {
  padding: 30px 0px 24px;
}
.school-detail-main-container .stu-detail-terms-collapse {
  border-radius: 12px !important;
  padding: 20px 0px;
}
.school-detail-main-container .box-shadow-school {
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.04);
}
.school-detail-sub-container-row span.anticon-left {
  height: 29px;
  width: 29px;
  font-size: 29px;
  color: #c3c6cd;
}
.school-detail-sub-container-row .terms-total {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #2b323b;
}
.school-detail-sub-container-row .school-terms {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #2b323b;
}
.school-detail-search-container .school-search-box {
  border: 1px solid #656c7c !important;
  border-radius: 8px !important;
  width: 200px;
}
.school-search-button {
  background-color: #2f74d3 !important;
  border-radius: 100px !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  min-height: 50px;
  min-width: 100px;
}
.school-search-button span {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  /* line-height: 22px !important; */
  color: #ffffff !important;
}

.menu-filter-school {
  padding: 10px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
  border-radius: 12px;
  overflow: scroll;
  height: 300px;
}
.menu-filter-school .menu-filter-school-sub-container {
  border-bottom: 1px solid #edf0f5;
  padding-bottom: 5px;
}
.menu-filter-school .menu-filter-school-btn-container {
  display: flex;
  gap: 10px;
  padding-left: 30px;
  justify-content: flex-end;
}
.reset-btn {
  background: #f2f9fe;
  border-radius: 100px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #0049a4;
}
.school-table-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px !important;
  justify-content: space-between;
  padding-right: 15px;
}
.school-table-head .table-head {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #2b323b;
  padding-right: 10px;
}
.school-table-head span {
  padding: 5px;
}
.menu-filter-school-sub-container span {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background-color: #f2f9fe;
  border-radius: 4px;

  color: #2f74d3;
}
.apply-btn {
  background: #2f74d3;
  border-radius: 100px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}
.school-detail-sub-container-row .back-btn-col {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.school-detail-collapse-data p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  padding-top: 12px;
  margin-bottom: 12px !important;
  color: #656c7c;
}
.school-detail-collapse-data span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  padding-top: 13px;
  color: #000000;
}
.school-detail-collapse .border-bottom {
  border-bottom: 1px solid #edf0f5;
}
.school-detail-main-container .school-edit-form-heading {
  display: flex;

  justify-content: space-between;
  border-bottom: 1px solid #edf0f5;
}
.school-edit-form-heading .header-edit {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  padding-left: 26px;
  padding-bottom: 15px;
}
.school-edit-form-heading .edit-terms-check {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  padding-right: 64px;
  padding-bottom: 15px;
  color: #2f74d3;
  .ant-switch {
    height: 32px;
    width: 64px;
    .ant-switch-handle {
      top: 6px !important;
    }
  }
}

.school-detail-main-container .degree-edit-card {
  margin-top: 20px;
  padding-bottom: 20px;

  .update-btn-col {
    display: flex;
    align-items: flex-end;
  }
}

.bank-edit-main-container {
  height: 100vh;
}

.school-detail-main-container .bank-edit-btn-row {
  margin-top: 30px;
  padding-right: 40px;
  .bank-edit-btn-inner-row {
    justify-content: space-between;
  }
}
.school-detail-main-container .school-term-formcontainer {
  padding: 15px 26px 0px 26px;
  padding: 16px;
}
.school-detail-main-container .school-edit-col-container {
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
  border-radius: 12px;
  padding: 20px 0px 25px 0px;
  // margin: 0px 20px;
  .state-select {
    .ant-select-selector {
      min-width: 170px;
    }
  }
}

.school-detail-main-container .pd-custom {
  padding-bottom: 8px !important;
}
.selected-card {
  // margin-right: 14px;
  // margin-left: 14px;
}
.school-term-col-container {
  margin-left: 16px;
  // background: #f7f8fb;
  padding: 10px 0;
  border-radius: 16px;
}

.school-term-formcontainer {
  .left-side-form-item {
    padding-right: 30px !important;
  }
  .right-side-form-item {
    padding-left: 30px !important;
  }
  .ant-form-item {
    .ant-select-lg {
      width: 100%;
    }
  }
}

.school-term-formcontainer .ant-input {
  border-radius: 8px !important;
}
.school-term-formcontainer label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: #656c7c;
}
.school-detail-main-container .edit-main-cont {
  margin: auto;
}

.school-detail-main-container {
  .main-outer-container {
    max-width: 1748px;
    margin-left: 40px;
    margin-right: 40px;
  }
}

.update-btn-container {
  margin-bottom: 20px;
}
.edit-school-input-id {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.add-school-modal .ant-modal-content {
  border-radius: 8px !important;
}

.add-school-modal .ant-modal-header {
  border-radius: 8px 8px 0 0;
}

.add-school-modal .modal-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.add-school-modal .modal-title svg {
  font-size: 1.5rem;
  color: #c3c6cd;
}

.add-school-modal .ant-form-item {
  margin-bottom: 15px !important;
}

.add-school-modal label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #656c7c;
}

.add-school-modal input {
  border-radius: 8px !important;
}

.add-school-modal .ant-select-selector {
  border-radius: 8px !important;
}

.add-school-modal .ant-input-number {
  width: 100%;
  border-radius: 8px !important;
}

.add-school-modal .w-100 {
  width: 100% !important;
}

.add-school-modal .switch-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #2f74d3;
  margin-left: 8px;
}

.add-school-modal .degree-row {
  border-bottom: 1px solid #edf0f5;
  padding: 0px 8px;
}
.mt-16 {
  margin-top: 16px;
}

.add-school-modal .degree-col {
  padding: 15px 0;
}
.add-school-modal .selected-degree {
  background-color: #e6f4ff;
  border: 1px solid #91caff;
  border-radius: 8px;
  padding-top: 0px;
  // padding-left: 10px;
}

.add-school-modal .degree-row .degree-name,
.selected-degree-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2b323b;
}

.add-school-modal .major-row {
  border-bottom: 1px solid #edf0f5;
  padding: 10px 0;
}

.major-section {
  max-height: 300px;
  overflow-y: scroll;
}

.add-school-modal .major-col {
  // background: #f7f8fb;
  // padding: 10px 0;
}
.selected-degree-name {
  margin-bottom: 0px;
  margin-top: 6px;
}
.selected-row {
  max-height: 380px;
  overflow-y: auto;
}
.add-school-modal .ant-switch-checked {
  background-color: #2f74d3;
}

.add-school-modal .major-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.add-school-modal .major-header .heading {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  margin: 0 !important;
}

.add-school-modal .major-header input {
  max-width: 200px;
}

.add-school-modal .search-degree-row {
  padding: 0px 0 20px 0;
}

.add-school-modal .pt-30 {
  padding-top: 30px;
}
.selected-degree-row {
  // background-color: #f7f8fb;
  padding: 10px 10px;
}
.selected-degree-name {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2b323b;
}
.degree-container {
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  padding-left: 16px;
  // gap: 10px;
}
.major-search {
  padding: 10px 26px 0px 16px;
  margin-bottom: 8px;
}

.coding-school-landing-url {
  .ant-pagination {
    justify-content: center !important;
  }
}

.image-note {
  margin: 10px 0 0 0;
  font-size: 14px;
  color: #c1c1c1;
  font-style: italic;

  b {
    color: #696d72;
  }
}

.ant-popover-inner {
  border-radius: 4px;

  .ant-popover-message-title {
    font-size: 14px;
    font-weight: 700;
    color: #2b323b;
  }
}
