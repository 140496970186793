.student-detail-container {
  // height: 100vh !important;
}

.student-list-overview-header {
  padding: 20px 20px 0;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.04);

  .setting-icon {
    font-size: 18px !important;
  }

  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    color: #2b323b;
  }

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #656c7c;
    padding-bottom: 8px;
    border-bottom: 3px solid #ffffff;
    cursor: pointer;
  }

  h3:hover {
    color: #000000;
  }

  .active-tab {
    color: #000000;
    border-bottom: 3px solid #5fa1ff;
  }

  .backButtonList {
    cursor: pointer;
  }
}

.application-list-table-container {
  padding: 20px 20px 20px 40px;
  .filter-applied-container {
    // border-radius: 8px !important;
    color: black;
    font-weight: 600;
    max-width: 350px;
    // border: 1px solid #d9d9d9;
    display: flex;
    gap: 5px;
    // padding: 5px;

    p {
      margin-bottom: 4px;
    }
  }
  // overflow-y: auto;
  .search-input {
    .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }

    .ant-btn.ant-btn-default.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
      border-radius: 0 8px 8px 0 !important;
    }
  }

  .cursor-pointer {
    cursor: pointer !important;
  }
  .application-list-table {
    th {
      background: #f7f8fb !important;
      font-weight: 700 !important;
      font-size: 12px !important;
      line-height: 16px !important;
      color: #656c7c !important;
    }

    td {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      color: #2b323b !important;
    }

    .student-name {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      color: #2f74d3 !important;
      cursor: pointer;
    }

    .ibr-id-badge {
      font-weight: 700;
      font-size: 12px;
      line-height: 24px;
      color: #0049a4;
      padding: 3px 6px;
      background: #f2f9fe;
      border-radius: 4px;
    }
  }
}

.stu-list-action-badges-container {
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #edf0f5;
  border-radius: 4px;
  padding: 10px;
  width: fit-content;
}
.stu-list-action-badges {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #656c7c;
}

.popup {
  animation-name: fadeIn;
  animation-duration: 0.4s;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0px;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  li {
    clear: both;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
    padding: 16px 16px;
    transition: all 0.3s;
    white-space: nowrap;
    -webkit-transition: all 0.3s;

    i {
      margin-right: 8px;
    }
  }
}
