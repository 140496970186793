.create-role-container {
  background-color: #f0f2f5;

  .role-heading {
    padding: 20px;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.04);
    background-color: #ffffff;

    h2 {
      font-weight: 700 !important;
      font-size: 30px !important;
      line-height: 41px !important;
      color: #2b323b !important;
    }
  }

  .create-role-body {
    padding: 30px 40px;
  }
}

.permission-list {
  border-radius: 12px;
  background: #fff;
  padding: 24px;
  margin: 20px 0;

  .ant-checkbox-group {
    display: block;
  }

  .service-name {
    color: #242a32;
    font-family: Open Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-bottom: 15px;
    border-bottom: 2px solid #dfe1e6;
  }

  .col-width {
    max-width: 40px;
  }
  .permissions-col:nth-child(odd) {
    border-right: 1px solid #dfe1e6;
    padding-right: 40px;
  }

  .permissions-col:nth-child(even) {
    padding-left: 40px;
  }

  .permission-name {
    color: #656c7c;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .ant-checkbox-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 10px 0;

    span:first-child {
      width: 18px;
    }
    span:last-child {
      width: 90%;
      padding: 0;
    }
  }
}
