.student-overview-section .export-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid #c3c6cd;
  // padding: 10px 35px;
  width: 50%;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #656c7c;
  padding: 5px 24px !important;
  min-height: 40px;
  height: 40px !important;

  button {
    height: 40px;
    font-weight: 700;
    font-size: 16px !important;
    border: 1px solid #c3c6cd;
  }

  .ant-btn-compact-first-item {
    width: 150px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  .ant-btn-compact-last-item {
    width: 40px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
}

.warning-icon-orange {
  color: #ff845e !important;
  margin-right: 5px;
}

.warning-icon-gray {
  color: #2b323b !important;
  padding-left: 60px;
  font-size: 20px;
}

.student-overview-section .panel-header-attention {
  display: flex;
  align-items: center;
}

.student-overview-section .f-20 {
  font-size: 20px !important;
  color: #ff845e !important;
  padding-top: 7px !important;
}

.student-overview-section .f-18 {
  font-size: 18px !important;
}

.admin-stu-overview-filter-drawer-button-sec-2 {
  display: flex;
  flex-direction: column !important;
}

.admin-stu-overview-filter-drawer-button-sec-2 button {
  text-align: left;
}

.stu-overview-load {
  text-align: center;
  margin: 25px 0;
}

.overview-custom-filter-container {
  margin-top: 19px;
  padding: 10px 0 0px 0;
  border-top: 1px solid #c3c6cd;
}

.filter-funding-semester-title {
  font-size: 18px;
}

.overview-bucket-main-div {
  padding: 30px 0;
}

.overview-bucket-main-div .containers {
  background: #ffffff;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
  border-radius: 12px;
}

.overview-bucket-main-div .containers .title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  padding: 15px;
  border-bottom: 1px solid rgba(43, 50, 59, 0.4);
  text-align: center;
}

.three-days-badge {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #2f74d3;
  background: #f2f9fe;
  border-radius: 4px;
  padding: 5px;
}

.overview-bucket-main-div .containers .count {
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 70px;
  cursor: pointer;
  padding: 15px;
  text-align: center;
}
.red-color {
  color: red !important;
}

.fill-green {
  color: #5ccd83 !important;
}

.fill-red {
  color: #f22d55 !important;
}

.text-green.mr-2 {
  color: #5fa1ff;
  margin-right: 3px;
}

.student-status-funded,
.student-status-failed,
.student-status-cancelled,
.student-status-approval,
.student-status-pending,
.student-status-approved {
  border-radius: 4px;
  font-weight: 700;
  padding: 3px 8px 5px;
}

.record-detail-status {
  display: inline-block;
  overflow: hidden;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
}

.Cancelled-status {
  color: #f22d55;
  background: #fde5ea;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 8px;
}

.student-status-funded {
  background: #e4faec;
  color: #25a050;
}

.student-status-failed,
.student-status-cancelled {
  color: #f22d55;
  background: #fde5ea;
}

.student-status-failed.red-flag {
  background: #fff9dc !important;
}

.student-status-pending,
.student-status-approval {
  background: #fff9dc;
  color: #ff845e;
}

.background-transparent {
  background: transparent;
}

.student-status-approved {
  background: #e4faec;
  color: #25a050;
}

.overview-bucket-main-div .containers .color-golden {
  color: #e49006;
}

.overview-bucket-main-div .containers .color-red {
  color: #fc4444;
}

.export-button {
  border: 2px solid #2f74d3;
  border-radius: 25px;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  color: #2f74d3;
}

@media screen and (max-width: 600px) {
  .student-overview-drawer-window .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}
