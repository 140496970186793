.alert-card{
    margin-left: 2%;
    margin-right: 8%;
    
    .alert-crd-heading {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #2B323B;
    }
    .alert-div {
        background: #FFF9DC;
        padding-top: 4px;
        margin-top: 8px;
    
    .card-content-row{
        align-items: center;
        
        height: 54px;
        padding-left: 3%;

        .card-content-col2{
            display: flex;
        
        
        .card-content {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #2B323B;
            margin: 0px !important;
            padding-left: 8px;
        }
        .session-reject-span{
            color: #f22d55;
            font-weight: 700;
            font-size: 16px !important;
        }
    }
    .session-rejected-btn-cols{
        text-align: center;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #2f74d3;
        cursor: pointer;
    }
    }
    .session-rejected-details-row{
        padding-left: 6%;
        .s-r-detail-point{
            margin-bottom: 0.5rem !important;
            font-size: 13px;
            .s-r-detail-head{
                font-weight: 700;
                color: #656c7c;
            }
        }
    }
}
.ant-alert-warning {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
    margin-top: 12px;
    height: 54px;
}
}