.main-header-layout {
  font-style: normal;

  .stud-name-row {
    padding: 25px 24px 0px 16px;
    align-items: center;
    justify-content: space-between;

    .back-icon-col {
      position: fixed;
      z-index: 99;
      cursor: pointer;
      margin-top: 12px;
      margin-left: 8px;
    }

    .stud-name-inner-row {
      position: relative;
      left: 10%;
      margin: 4px 0px;

      .back-icon-col {
        font-size: 16px;
        line-height: 1;
        display: flex;
        padding: 8px 24px 0px 16px;
        justify-content: center;
        cursor: pointer;
      }
      .enquiry-header {
        margin-left: 20px;
      }
      .stud-name {
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 41px;
        color: #2b323b;
        .ibr-user-type-span {
          margin-left: 24px;
          padding: 3px 8px 5px 8px;
          border-radius: 4px;
          background: #e6eefa;
          display: flex;
          align-items: center;
          font-family: Open Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          color: #2f74d3;
        }
      }
      .stud-id-content {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        .stud-id-value {
          color: #2f74d3;
          font-weight: 700;
        }
        .copy-display-option {
          color: #2f74d3;
          font-weight: 700;
          margin-left: 4px;
          img {
            height: 24px;
            width: 24px;
          }
        }
      }
      .blue-text {
        color: #2f74d3 !important;
      }
      .red-text {
        color: #f22d55 !important;
      }
    }

    .select-col {
      height: 50px !important;
      .select-ibr-row {
        justify-content: end;
        align-items: center;
        min-height: 100%;

        .select-ibr-col {
          display: flex;
          align-items: baseline;

          .id-space {
            height: 50px !important;
            width: 100%;
            justify-content: space-around;
            border: 1px solid #898d96;
            border-radius: 8px;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #2b323b;
            padding: 0px 16px;
          }

          .select-field {
            width: 100%;
            font-weight: 700;

            .ant-select-selector {
              height: 48px !important;
              align-items: center;
              padding: 14px 24px;
              gap: 8px;
              border-radius: 8px !important;

              span.ant-select-selection-placeholder {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: #2b323b;
              }

              span.ant-select-selection-item {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: #2b323b;
              }
            }
          }

          .select-result-div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px 16px !important;
            gap: 8px;
            height: 48px !important;
            background-color: #e4faec;
            border-radius: 0px 8px 8px 0px;

            .select-result {
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 22px;
              text-align: center;
              color: #25a050;
            }
          }
        }
      }
    }
  }

  .tabs-row {
    justify-content: space-between;

    .tabs-col {
      min-width: 100%;

      .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        height: 4px !important;
        background: #5fa1ff;
      }
    }
  }

  .tab-main-cont {
    .ant-tabs-nav {
      margin: 0 !important;
    }

    .ant-tabs-nav {
      padding-left: 20px !important;
      padding-right: 2%;
      box-shadow: 0px 8px 8px rgb(0 0 0 / 4%);
    }

    .ant-tabs-tab-btn {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #656c7c;
    }

    .ant-tabs-tab-active {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;

      .ant-tabs-tab-btn {
        color: #000000 !important;
      }
    }
  }

  .cancel-btn-col {
    padding-right: 2%;
  }

  .red-btn {
    border: 1px solid #f22d55;
    border-radius: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 8px;
    width: 126px;
    height: 38px;
    transition: all 0.3s;
    cursor: pointer;

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 22px;
      color: #f22d55;
    }
    &:hover {
      background: #f22d55 !important;
      span {
        color: #ffffff !important;
      }
    }
  }
  .red-bg-btn {
    color: #f22d55;
    border-radius: 15px;
    background: #fde5ea;
    font-weight: bold;
  }
}

.ibr-list-dd {
  width: 372px;
  max-height: 250px !important;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 12px !important;
  box-shadow: 0px 2px 13px rgb(0 0 0 / 15%);
}
.admin-dropdown-bg-selected {
  background-color: #f7f8fb !important;
  padding: 10px 0;
  .ibr-list-options-row {
    padding: 0 10px;
    .stu-my-ibr-head-txt1 {
      font-size: 14px !important;
      color: #2b323b !important;
      font-weight: 600 !important;
      display: flex !important;
      justify-content: space-between !important;
      flex-wrap: wrap !important;
      .w-100 {
        width: 100% !important;
        .text-gray {
          color: #898d96;
        }
        .justify-content-between {
          justify-content: space-between !important;
        }
      }
    }
    .stu-my-ibr-mid-txt2 {
      color: #2b323b;
      font-size: 14px;
      display: flex !important;
      justify-content: space-between !important;
      flex-wrap: wrap !important;
      .stu-my-ibr-foot-txt {
        font-size: 14px;
        color: #898d96;
      }
    }
  }
}
.admin-dropdown-bg-plan {
  background-color: white !important;
  padding: 10px 0;
  .ibr-list-options-row {
    padding: 0 10px;
    .stu-my-ibr-head-txt1 {
      font-size: 14px !important;
      color: #2b323b !important;
      font-weight: 600 !important;
      display: flex !important;
      justify-content: space-between !important;
      flex-wrap: wrap !important;
      .w-100 {
        width: 100% !important;
        .text-gray {
          color: #898d96;
        }
        .justify-content-between {
          justify-content: space-between !important;
        }
      }
    }
    .stu-my-ibr-mid-txt2 {
      color: #2b323b;
      font-size: 14px;
      display: flex !important;
      justify-content: space-between !important;
      flex-wrap: wrap !important;
      .stu-my-ibr-foot-txt {
        font-size: 14px;
        color: #898d96;
      }
    }
  }
}
.admin-dropdown-bg-plan:hover {
  background-color: #f7f8fb !important;
}
.cancelation-modal-wrp {
  padding: 0.8rem;
  .are-you-sure-heading {
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #000000;
    margin-bottom: 16px;
  }
  .support-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #000000;
  }
  .cancelling-cannot-head {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #000000;
    margin-bottom: 1.5rem;
  }
  .reason-wrp {
    margin: 0 1rem;
    .reason-to-cancel-application {
      background: #ffffff;
      box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
      border-radius: 4px;
      padding: 0.8rem 1rem;
      margin: 0.5rem 0;
      font-weight: 400;
      cursor: pointer;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
    }
    .boder-select {
      border: 1px solid #000;
    }
    .cancel-text-area {
      background: #ffffff;
      border: 1px solid #c3c6cd;
      border-radius: 8px;
      padding: 1rem;
      width: 100%;

      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
    }
    .query-error {
      color: red;
      font-size: 14px;
    }
  }
  .cancel-modal-application-btn-container {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .application-contect-support {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 5px;
    text-align: center;
    color: #000000;
  }
}

.extra-cnt-div {
  display: flex;
  gap: 8px;
}

.Cancelled-status {
  color: #f22d55;
  background: #fde5ea;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  .Tooltip-cancellation .ant-tooltip-inner {
    background-color: white;
    .Tooltip-text {
      background-color: white;
      color: black;
    }
    .text-Tooltip {
      color: grey;
    }
  }
}
.back-to-reason-option {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}

.ibr-list-dd::-webkit-scrollbar {
  width: 6px;
}
.ibr-list-dd::-webkit-scrollbar-thumb {
  background: #367bda;
  border-radius: 12px;
}
.ibr-list-dd::-webkit-scrollbar-thumb:hover {
  background: #367bda;
}

@media screen and (max-width: 991px) {
  .main-header-layout {
    .stud-name-row {
      .stud-name-inner-row {
        .back-icon-col {
          justify-content: end !important;
          padding-right: 2%;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .main-header-layout {
    .stud-name-row {
      padding-top: 8%;

      .stud-name-inner-row {
        .back-icon-col {
          justify-content: center !important;
        }
      }
    }

    .stud-id-row {
      .stud-id-inner-row {
        padding-top: 4px;
      }
    }
  }
}

@media screen and (max-width: 541px) {
  .main-header-layout {
    .tabs-row {
      flex-direction: column-reverse;
      padding-top: 8px;

      .cancel-btn-col {
        padding-left: 3%;
      }
    }
  }
}
