.student-list-container {
  padding: 20px 20px 20px 40px;

  .search-filter-row {
    padding: 20px 0;
    .export-button {
      border: 2px solid #2f74d3;
      border-radius: 25px;
      font-weight: 700;
      font-size: 12px;
      line-height: 22px;
      color: #2f74d3;
    }

    .search-input {
      .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
      }

      .ant-btn.ant-btn-default.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
        border-radius: 0 8px 8px 0 !important;
      }
    }

    .filters-list {
      color: black;
      font-weight: 600;
      .bucket-filter {
        margin: 0 0 10px 0;
        display: flex;
        gap: 5px;
      }
    }
  }

  .page-details {
    display: flex;
    justify-content: end;
    gap: 12px;

    span {
      font-size: 16px;
    }
  }

  .student-list-table {
    th {
      background: #f7f8fb !important;
      font-weight: 700 !important;
      font-size: 12px !important;
      line-height: 16px !important;
      color: #656c7c !important;
    }

    td {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      color: #2b323b !important;
    }

    .student-name {
      color: #2f74d3 !important;
      cursor: pointer;
    }

    .ibr-id-badge {
      font-weight: 700;
      font-size: 12px;
      line-height: 24px;
      color: #0049a4;
      padding: 3px 6px;
      background: #f2f9fe;
      border-radius: 4px;
    }
  }

  .antd-pagination-student {
    margin: 30px 0 !important;
  }
}
