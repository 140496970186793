.student-overview-section {
  width: 96%;
  margin: 15px auto;

  .fall-title {
    font-style: normal;
    font-weight: 700 !important;
    font-size: 23px;
    line-height: 31px;
    color: #2b323b;
  }

  h4 {
    display: inline-block;
    margin-right: 40px;
  }
  h5 {
    display: inline-block;
    margin-right: 8px;
    font-weight: 600;
    font-size: 16px;
  }
}

.student-overview-section .text-gray {
  font-size: 14px;
  color: #6c757d;
  margin-top: 10px;
}

.status-select-dropdown .ant-select-selector {
  border-radius: 8px !important;
}

.student-overview-section .attention-records-collapse {
  background: white;
  border: none;
  border-radius: 20px;
}

.student-overview-section .ant-collapse-content.ant-collapse-content-active {
  border-radius: 0 0 16px 16px !important;
  border-top: 1px solid #ff5622 !important;
}

.overview-record-detail-headings {
  padding-left: 10px;
}

.overview-record-detail {
  .record-detail-heading {
    font-weight: 700;
    font-size: 27px;
    line-height: 32px;
    .record-percentage {
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: #656c7c;
    }
  }
}

.student-overview-section .panel-header-attention {
  display: flex;
  align-items: center;
}

.student-overview-section .ant-collapse-header {
  border: 2px solid #ff5622 !important;
  border-radius: 16px 16px 16px 16px !important;
}
.student-utm-multiple-selector {
  padding: 0 20px;
}

.student-utm-multiple-selector .student-utm-multiple-selector-label {
  font-size: 12px;
  font-weight: 600;
  color: #6c757d;
}

.student-utm-multiple-selector .ant-select {
  min-width: 250px;
}

.student-utm-multiple-selector .ant-select-selector {
  border-radius: 8px !important;
}

.student-overview-funnel {
  background: #fff;
  box-shadow: 0px 2px 13px rgb(0 0 0 / 15%);
  border-radius: 16px;
  padding: 15px 0px;
}

.student-overview-funnel-header {
  padding: 10px 35px;
}
.chart-over-row {
  padding-left: 12px;
}

.student-funnel-row {
  align-items: flex-end;
  margin-top: 8px;
  .total-stud-col {
    .total-stud-count {
      font-weight: 700;
      font-size: 42px;
      line-height: 57px;
      color: #000000;
    }
    .text-black {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      margin-bottom: 0px !important;
    }
  }
  .funnel-data-col1 {
    align-items: flex-end;
    .student-funnel-section {
      width: 50%;
      p {
        margin-bottom: 0px !important;
      }
    }
  }
  .student-funnel-section-border-left {
    border-left: 1px solid #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50%;
    align-items: flex-end;
    padding-right: 8px;
    p {
      margin-bottom: 0px !important;
    }
  }
}

.records-detail-row {
  margin-top: 4%;
  padding: 0px 15px;
}

.admin-student-overview-chart .apexcharts-toolbar {
  display: none !important;
}

.overview-record-detail .bg-gray {
  background: #f7f8fb;
}

.overview-record-detail .h-60 {
  height: 60px;
}
.w-100 {
  width: 100% !important;
}

.date-picker-dropdown .anticon.anticon-down {
  color: #367bda !important;
}

.filter-divider-div {
  padding: 0;
  .drawer-filter-divider {
    border-top: 1px solid #e5e9f0;
  }
}

.overview-cancel-button {
  cursor: pointer;
  padding-top: 14px;
  color: #656c7c;
  font-weight: 700;
}
.overview-hover-export {
  width: 150px !important;
  margin: 0 !important;
  text-align: start !important;
  padding: 0 !important;
  color: black !important;
  font-weight: 700;
}

.overview-chart {
  display: flex;
  justify-content: center;
  align-items: center;

  .apexcharts-text {
    font-size: 10px !important;
  }
}

.admin-stu-overview-filter-drawer-button-sec
  .ant-radio-group
  .ant-radio-button-wrapper {
  margin: 0 5px !important;
}

.stu-overview-load {
  text-align: center;
  margin: 25px 0;
}

.admin-stu-overview-filter-drawer-button-sec .sem-radio-buttons,
.approve-btn-certification {
  max-width: 130px !important;
  text-align: center !important;
  border-radius: 25px !important;
  width: 33%;
  border: 2px solid #367bda;
  border-left: 2px solid #367bda !important;
}
.overview-filter-external {
  font-weight: 700;
  font-size: 12px;
  border-radius: 25px !important;
  width: 60%;
  border: 2px solid #367bda;
  border-left: 2px solid #367bda !important;
}

.ant-form label {
  font-size: 14px;
  color: #656c7c;
  font-weight: 600;
  line-height: 25px;
}

.Range-drawer-overview {
  padding-left: 10px;
}
.admin-stu-overview-filter-drawer-button-sec
  .sem-radio-buttons.ant-radio-button-wrapper:not(:first-child)::before {
  background: none;
}

.student-overview-drawer-window {
  .filter-type-headers {
    margin-left: 45px;
    margin-top: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #2b323b;
  }
  .quick-filter-margin {
    margin-left: 45px;
    margin-top: 24px;

    .ant-space-vertical {
      line-height: 19px;
    }
  }
  .utm-filter-rd {
    margin-left: 48px !important;
    margin-right: 110px !important;
  }
}

.student-overview-drawer-window .ant-drawer-header-title {
  flex-direction: row-reverse;
}
.student-overview-drawer-window .ant-drawer-title {
  font-weight: 700 !important;
  font-size: 28px !important;
  line-height: 32px !important;
  color: #242a32 !important;
}

.student-overview-drawer-window .ant-drawer-content-wrapper {
  width: 500px !important;
}

.student-overview-drawer-window .ant-drawer-header {
  padding: 20px 22px 20px 45px !important;
}

.student-overview-drawer-window .ant-drawer-body {
  padding: 15px 22px;
}

.overview-custom-filter-container {
  margin-top: 19px;
  padding: 10px 0 0px 0;
  border-top: 1px solid #c3c6cd;
}

.filter-funding-semester-title {
  font-size: 18px;
}

@media screen and (max-width: 600px) {
  .student-overview-drawer-window .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .bg-w-100 {
    width: 100% !important;
  }
}
