.transcript-varification-info-container {
  background: #fef2f4;
  border-radius: 8px;
  display: flex;
  padding: 0.5rem 1rem;
}
.student-contected-heading-radio {
  display: flex;
  align-items: center;
  gap: 5px;
}
.student-contected-heading-radio label {
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 600;
}
.student-contected-heading-radio input {
  ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Chrome, Safari, Opera */
  transform: scale(1.1);
}

.aprroval-modal-approval-btn {
  background: #2f74d3 !important;
  color: white;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  border-radius: 100px !important;
}
.aprroval-modal-approval-btn:hover {
  cursor: pointer;
  color: white !important;
}
.transcript-confirm-btn {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  background: #2f74d3 !important;
  border-radius: 100px !important;
  color: white !important;
}
.transcript-confirm-btn:disabled {
  background-color: #f5f5f5 !important;
  color: #bec3ca !important;
}
.transcript-approval-modal .ant-modal-content {
  border-radius: 20px !important;
}
.transcript-approval-modal .ant-modal-header {
  border-radius: 20px 20px 0px 0px !important;
  .ant-modal-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
  }
}

.transcript-approval-modal {
  .ant-modal-close-icon {
    font-size: 20px;
  }

  .button-size {
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 22px;
  }
}

.recal-dti-div {
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  color: #656c7c;
  .recal-dti-val {
    font-size: 16px !important;
    line-height: 26px !important;
    font-weight: 400 !important;
    color: #000000 !important;
  }
}

.verify-inc-form {
  .ant-form-item {
    margin-bottom: 0px;
  }
}

.income-verify-instruct {
  margin-top: 26px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #2b323b;
}
.income-verify-guide {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #2b323b;
  margin-bottom: 24px;
}

.transcript-varification-info-container .info-head {
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #f22d55;
}
.info-bottom {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #c31033;
  background-color: #fef2f4;
}

.transcript-varification-info-container .img-wrp {
  margin-right: 0.8rem;
}

p.transcript-details {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin: 1rem 0;
  color: #2b323b;
}
.ant-divider-horizontal {
  margin: 4px 0;
}

.degree-to-transcript-container {
  /* background: #fef2f4; */
  border-radius: 8px;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #edf0f5;
}
.degree-to-transcript-container-part {
  background: #fef2f4;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // max-height: 100px;
}
.mismatch-container {
  margin-top: 24px;
}

.divider {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 0.5rem;
  margin-top: 2px;
}

.degree-to-transcript-container .degree-to-associate {
  display: flex;
  align-items: center;
  gap: 10px;

  padding-left: 10px;
}
.degree-to-transcript-container-part .degree-to-associate {
  display: flex;
  align-items: center;
  gap: 10px;

  padding-left: 10px;
}
.degree-to-transcript-container .degree-to-associate .original-values {
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
}
.degree-to-transcript-container svg {
  height: 30px;

  flex: 1;
}
.degree-to-transcript-container-part svg {
  height: 30px;

  flex: 1;
}

.degree-to-transcript-container .from-transcript-to-bachlor-wrp {
  /* flex: 2; */
}
.action-container-transcript {
  display: flex;
  gap: 10px;
  align-items: center;
}
.from-transcript-to-bachlor-wrp .padding {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 19px;
  color: #656c7c !important;
}
.degree-to-associate .trans {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 19px;
  color: #656c7c !important;
}

.leave-a-option-head {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-top: 0.5rem;
  margin-bottom: 0.3rem;
  color: #656c7c;
}

.write-your-notes-textarea {
  width: 100%;
  border: 1px solid #656c7c;
  border-radius: 8px;
  padding: 0.4rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #656c7c;
}
.student-contected-heading {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #656c7c;
  margin: 0.5rem 0;
}

.approve-decline-btn-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.approve-decline-btn-group .approve-btn {
  height: 50px;
  width: 116px;
  background: #2f74d3;
  border-radius: 100px;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-left: 1rem;
}

.approve-decline-btn-group .approve-btn:disabled {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.25);
}

.approve-decline-btn-group .decline-btn:disabled {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.25);
}

.approve-decline-btn-group .decline-btn {
  height: 50px;
  width: 116px;
  background: #edf0f5;
  border-radius: 25px;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #2b323b;
}

.inc-verify-doc-div {
  .stu-detail-doc-card-doc-list {
    border-bottom: 1px solid #e5e9f0;
  }

  .stu-detail-docs-card-doc-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #2b323b;
    margin: 0 !important;
  }

  .stu-detail-app-collapse-date-txt {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #898d96;
  }

  .student-admin-isa-request-r-text {
    text-align: end;
  }

  .app-collapse-blue-txt {
    font-weight: bold;
    font-size: 12px;
    color: #367bda;
  }
}

.admin-action-modal {
  .detail-container {
    p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 4px !important;
      padding: 5px 10px;
      .action-heading {
        font-size: 14px;
        margin: 0 !important;
        padding: 0 !important;
        font-weight: 700 !important;
      }

      .action-detail {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #656c7c;
        max-width: 70%;
      }

      &:nth-child(odd) {
        background: #f2f9fe;
      }
    }
  }
}
