.app-status-outer-layout{
    background-color: #ffffff;
    .app-status-main-row{
        .app-status-sidebar-col{
            background: #F2F9FE;
            .app-status-sidebar{
                padding: 16px 4px;
                .sidebar-btn-row{
                    align-items: baseline;
                    padding-top: 2px;
                    .btn-icon-col{
                        padding-bottom: 5px;
                        .green-tick{
                            color: #5ccd83 !important;
                        }
                        .red-mark {
                            
                            color: #f22d55 !important;
                          }
                          .orange-mark{
                            color: #ff845e !important
                          }
                    }
                    .sidebar-btn-col{
                        .inactive-btn{
                            padding: 6px 8px !important;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 22px;
                            color: #2B323B;
                        }
                        .inactive-btn:hover{
                            background: none !important;
                        }
                        .active-btn{
                            padding: 6px 8px !important;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 22px;
                            color: #0671FF;
                        }
                        .active-btn:hover{
                            background: none !important;
                        }
                    }
                }
            }
        }
        .app-status-detail-col{
            .app-status-inner-layout{
                background: #ffffff;
            }
        }
    }
    .app-status-detail-col{
        padding-bottom: 8px;
    }
}