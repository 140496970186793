.admin-list-container {
  .header {
    padding: 20px;

    h2 {
      font-weight: 700;
      font-size: 30px;
      line-height: 41px;
      color: #2b323b;
    }
  }

  .Add-admin-btn-row {
    padding: 20px;
    gap: 20px;
    background: #f0f2f5;
    .search-input {
      // margin-left: 10px;
      .ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
      }

      .ant-btn.ant-btn-default.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
        border-radius: 0 8px 8px 0 !important;
      }
    }
  }

  .admin-table-row {
    background: #f0f2f5;
    padding: 0 20px 20px;

    .table-cover {
      box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
      border-radius: 12px;
      padding: 8px;
      background-color: #ffffff;
    }

    .list-loader {
      position: relative;
      top: 50%;
      z-index: 100;
    }

    .admin-list-table {
      .table-heading {
        color: #656c7c;
        font-size: 12px;
        font-weight: 700;
        margin: 0;
      }

      .ant-table-cell {
        color: #242a32;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
      }
    }
  }

  .admin-active-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 8px 5px;
    gap: 8px;
    background: #e4faec;
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #25a050;
    width: 54px;
    height: 24px;
  }

  .admin-pending-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 8px 5px;
    gap: 8px;
    background: #edf0f5;
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #656c7c;
    width: 60px;
    height: 24px;
  }

  .admin-progress-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 8px 5px;
    gap: 8px;
    background: #fff9dc;
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #f1c227;
    width: 103px;
    height: 24px;
  }

  .admin-expired-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 8px 5px;
    gap: 8px;
    background: #fef2f4;
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #fc4444;
    width: 65px;
    height: 24px;

    .resand-btn {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #4492fc;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  .stu-list-admin-btn {
    padding: 0 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #367bda !important;
  }
  .student-id-list-admin {
    font-size: 7px;
    line-height: 20px;
    color: #898d96;
  }

  .admin-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #4492fc;
    cursor: pointer;
  }
  .permission {
    cursor: pointer;
  }
}

.add-new-admin-modal {
  .ant-modal-header {
    border-radius: 8px;
  }

  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #2b323b;
  }

  .ant-modal-close-x {
    height: 100%;

    svg {
      font-size: 22px;
      font-weight: 800;
    }
  }

  .input-phone {
    width: 100%;
    border: 1px solid #c3c6cd;
    border-radius: 8px;
    height: 40px;
    padding-left: 11px;
  }

  .input-phone::selection {
    border: 1px solid #1890ff !important;
  }

  .input-phone:focus {
    border: 1px solid #46a3fa !important;
    outline: 0px !important;
    box-shadow: 0 0 1pt 1pt #bbdbfa;
  }
  .ant-form-item {
    margin-bottom: 16px !important;
  }
}

.sucess-modal {
  .ant-modal-content {
    border-radius: 8px !important;
  }
  .ant-modal-title {
    color: #2b323b;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
  .sucess-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      color: #2b323b;
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
      margin-top: 20px;
    }
  }
}

// role list

.role-list-view-container {
  .header {
    padding: 20px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.04);

    h2 {
      font-weight: 700;
      font-size: 30px;
      line-height: 41px;
      color: #2b323b;
    }
  }

  .edit-btn-row {
    padding: 30px 20px;
    border-bottom: 2px solid #dfe1e6;

    .student_manager {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color: #242a32;
    }
  }

  .role-list-tabs {
    padding: 20px 20px 0 20px;

    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #656c7c;
      padding-bottom: 8px;
      border-bottom: 3px solid #ffffff;
      cursor: pointer;
      margin-right: 20px;
    }

    h3:hover {
      color: #000000;
    }

    .active-tab {
      color: #000000;
      border-bottom: 4px solid #5fa1ff;
    }
  }

  .role-list-component {
    padding: 20px;
    background-color: #f0f2f5;

    .border-bottom {
      border-bottom: 2px solid #dfe1e6;
    }

    .role-list-container {
      box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
      border-radius: 12px;
      padding: 20px;
      background-color: #ffffff;

      .main-heading {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #242a32;
        margin-bottom: 10px !important;

        p {
          margin-bottom: 0 !important;
        }
      }

      .view-buttom {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #2b323b;
        opacity: 0.6;
      }
    }
    .checked-circle {
      color: #25a050;
    }
    .cross-icon {
      color: #f22d55;
    }

    .role-list-detail-container {
      padding-top: 20px;

      .border-right {
        border-right: 1px solid #dfe1e6;
        padding-right: 20px;
        margin-right: 20px;
      }

      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #656c7c;
      }

      .role-list {
        padding: 10px 0;
      }
    }

    .dismiss {
      opacity: 0.3;
    }
  }
  .permissions-row {
    .permissions-col {
      padding: 10px 0;
      p {
        color: #656c7c;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
    }

    .permissions-col-role-list {
      padding: 10px 0;
      p {
        color: #656c7c;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
    }

    .permissions-col-role-list-even {
      border-right: 1px solid #dfe1e6;
      padding-right: 40px;
    }

    .permissions-col-role-list-odd {
      padding-left: 40px;
    }
  }
}

// edit list

.edit-role {
  background-color: #f0f2f5;
  .edit-role-view-container {
    .header {
      padding: 20px 40px;
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.04);
      background-color: #ffffff;

      h2 {
        font-weight: 700;
        font-size: 30px;
        line-height: 41px;
        color: #2b323b;
      }
    }

    .title-buttons {
      margin: 20px;

      .role-title {
        p {
          margin-bottom: 0em;
        }
      }

      .student-manager {
        background-color: #ffffff;
        padding: 6px 60px 6px 30px;
        border: 1px solid #aeb1b8;
        border-radius: 8px;

        h1 {
          font-weight: 400;
          font-size: 24px;
          line-height: 24px;
          color: #242a32;
        }
      }
    }

    .edit-tabs {
      margin: 30px 0;
      padding: 20px 20px 0 30px;
      background-color: #ffffff;
      border: 1px solid #ffffff;
      border-radius: 12px;

      h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #656c7c;
        padding-bottom: 8px;
        border-bottom: 3px solid #ffffff;
        cursor: pointer;
        margin-right: 20px;
      }

      h3:hover {
        color: #000000;
      }

      .active-tab {
        color: #000000;
        border-bottom: 4px solid #5fa1ff;
      }
    }
  }
  .edit-role-component {
    padding: 20px;
    background-color: #f0f2f5;

    .border-bottom {
      border-bottom: 2px solid #dfe1e6;
    }

    .edit-role-container {
      box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
      border-radius: 12px;
      padding: 20px;
      background-color: #ffffff;

      .main-heading {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        color: #242a32;
        margin-bottom: 10px !important;

        p {
          margin-bottom: 0 !important;
        }
      }
      .view-buttom {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #2b323b;
        opacity: 0.6;
      }
    }
  }
  .edit-role-detail-container {
    padding-top: 20px;

    .ant-checkbox-group {
      display: contents !important;
    }

    .border-right {
      border-right: 1px solid #dfe1e6;
      padding-right: 20px;
      margin-right: 20px;
    }

    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #656c7c;
    }

    .edit-role-list {
      padding: 10px 0;
    }
  }
  .dismiss {
    opacity: 0.3;
  }
}

// delete modal

.delete-modal {
  width: 620px !important;
  .ant-modal-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #2b323b;
  }

  .ant-modal-footer {
    padding: 25px 16px 16px 16px;
  }

  .delete-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      color: #2f74d3;
      padding-top: 20px;
    }
  }
}

.delete-rate-modal {
  border-radius: 12px !important;
  .ant-modal-content {
    border-radius: 12px !important;
  }
  .ant-modal-close-x {
    svg {
      font-size: 22px;
      font-weight: 700;
      color: #c3c6cd;
    }
  }
  .ant-modal-body {
    padding-top: 30px !important;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #2b323b;
    text-align: center;
    padding: 40px 0 10px;
  }
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #2b323b;
    text-align: center;
    padding: 10px 0;
  }
  .ant-modal-footer {
    border: none;
    padding: 0 20px 20px !important;
  }
  .cancel-text-area {
    background: #ffffff;
    border: 1px solid #c3c6cd;
    border-radius: 8px;
    padding: 1rem;
    width: 100%;

    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }
  .query-error {
    color: red;
    font-size: 14px;
  }
}

// members

.role-list-members {
  padding-top: 20px;
  background-color: #f0f2f5;
}

.member-component {
  padding: 0 20px 20px 20px;
  // background-color: #f5f5f5;

  .member-table-row {
    padding-top: 20px;

    .table-cover {
      box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
      border-radius: 12px;
      padding: 8px;
      background-color: #ffffff;

      .member-table {
        .member-header {
          color: #656c7c;
          font-weight: 700;
          line-height: normal;
        }

        .member-data {
          color: #656c7c;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .remove-btn {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #2f74d3;
      cursor: pointer;
    }

    .ant-table-container table > thead > tr:first-child th:first-child {
      border-radius: 10px 0 0 10px !important;
    }

    .ant-table-container table > thead > tr:first-child th:last-child {
      border-radius: 0 10px 10px 0 !important;
    }
  }

  .no-member-image {
    background: #ffffff;
    padding: 100px;
    margin: 20px 0 0 0;
    border-radius: 12px;
  }
}

// assign role modal

.assign-role-modal {
  .ant-modal-content {
    border-radius: 12px !important;

    .ant-modal-header {
      border-radius: 12px !important;
    }
  }
  .ant-modal-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #2b323b;
  }

  .ant-modal-footer {
    padding: 25px 16px 16px 16px;
  }

  .ant-checkbox-group {
    width: 100%;
  }

  .ant-modal-body {
    padding: 20px 0 !important;
    height: 500px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .checkbox-container {
    margin-top: 20px;
  }

  .assign-role-body {
    padding: 18px 0;
    border-bottom: 1px solid #edf0f5;

    .assign-role-row {
      padding: 0 24px !important;

      .name-section {
        margin-left: 20px;
        gap: 30px;
        display: flex;
      }

      .input-search {
        padding: 24px;
        margin-bottom: 20px;
      }

      .name-first-letter {
        display: inline-block;
        background: aquamarine;
        border-radius: 50%;
        width: 25px;
        text-align: center;
        color: #ffffff;
      }

      p {
        margin-bottom: 0em !important;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: #242a32;
      }
    }
  }
  .assign-role-body:first-child {
    padding-top: 0 !important;
  }
  .assign-role-body:last-child {
    border: none !important;
    padding-bottom: 0 !important;
  }
}

.section-name {
  color: #242a32;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 15px 0;
}
