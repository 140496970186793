.admin-sqs-table .sqs-table-type-regular {
  color: #25a050;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.admin-sqs-table .sqs-table-type-error {
  color: #f22d55;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
}

.admin-sqs-table .sqs-tbl-btn {
  background: #2f74d3;
  border-radius: 24px;
  height: 31px;
  color: #ffffff;
}

.admin-sqs-table {
  .ant-table-thead {
    .ant-table-cell {
      font-weight: 600 !important;
      font-size: 13px !important;
      line-height: 18px !important;
      color: #2b323b !important;
    }
  }
}

.system-health-header {
  box-shadow: 0 2px 4px rgb(0 0 0 / 14%);

  .system-health-header-heading {
    padding: 16px 24px;

    .back-arrow {
      font-size: 27px;
      color: #367bda !important;
    }

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 40px;
      line-height: 54px;
      color: #242a32;
      margin-bottom: 10px !important;
      gap: 20px;
    }
  }

  .system-health-tabs {
    padding: 0 60px;
    h3 {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #656c7c;
      padding-bottom: 8px;
      border-bottom: 3px solid #ffffff;
      cursor: pointer;
    }

    h3:hover {
      color: #000000;
    }

    .active-tab {
      color: #000000;
      border-bottom: 3px solid #5fa1ff;
    }
  }
}

.embed-flower {
  padding: 20px 60px 0;
  color: #367bda !important;
  cursor: pointer;
}
