.main-doc-modal {
  // width: 765px !important;

  .ant-modal-body {
    padding: 12px !important;
  }
}

.doc-modal-pageNo {
  display: flex;
  justify-content: flex-end;
}

.doc-modal-btns {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.doc-modal-btns button {
  background: #367bda;
  color: #ffffff;
  border: none;
  padding: 5px 15px;
}

.main-doc-modal canvas {
  width: 715px !important;
  height: 855px !important;
}

.doc-modal-pdf-data {
  overflow: overlay;
  width: 100%;

  .react-pdf__Page {
    overflow: hidden;
  }
}

.download-button {
  font-weight: 700;
  font-size: 12px;
  color: #2f74d3;
  margin-left: 4px;
  cursor: pointer;
}
.E-concent-modal {
  .consent-headings {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
  }
  .consent-sub-headings {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
}
