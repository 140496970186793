.refund-header-container {
  width: 100%;
  padding: 32px 24px 5px 24px;
  border-bottom: 1px solid #e6eefa;
  background: #fff;
  .refund-text {
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .heder-blue-btn {
    min-height: 40px !important;
  }
  .active-tab {
    padding: 0 2px;
    cursor: pointer;
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    border-bottom: 4px solid #2f74d3;
  }
  .non-active-tab {
    cursor: pointer;
    color: #656c7c;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
  }
}

.action-reqired-card {
  background-color: #fff;
  padding: 16px 24px;
  border-radius: 8px;
  &:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  p {
    margin-bottom: 0px;
  }
  .tag-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .border-none {
    border: none !important;
    border-radius: 4px;
  }
  .tag-text {
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
  .expired-tag {
    color: #656c7c !important;
    background-color: #ebeff6 !important;
  }
  .card-heading {
    color: #111c2a;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }
  .date-text {
    color: #656c7c;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .admin-text {
    color: #656c7c;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .card-subheading {
    color: #656c7c;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
  .txt-right {
    text-align: right;
  }
  .card-arrow-section {
    height: 100%;
    border-left: 1px solid #ebeff6;
    padding-left: 24px;
    display: flex;
    align-items: center;
    justify-items: end;
  }
}
.refunds-addstudent-modal {
  .infinite-container {
    max-height: 350px;
    overflow-y: auto;
  }
  .text-end {
    text-align: end;
  }
  .cursor-notallow {
    cursor: not-allowed;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .refund-present-input {
    width: 90px;
    .ant-input {
      color: #000;
      font-family: "Open Sans";
      font-size: 16px !important;
      font-style: normal;
      font-weight: 600 !important;
    }
  }
  .line-height-24 {
    line-height: 24px !important;
  }
  .total-refund-txt {
    color: #000;
    text-align: right;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  .total-refund-box {
    border-radius: 8px;
    background: #f5f9fe;
    padding: 8px 20px;
  }
  .add-student-section {
    display: flex;
    justify-content: space-between;
    height: 80px;
    padding: 20px 16px;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #c3c6cd;
    background: #fff;
  }
  .placeholder-txt {
    color: #656c7c;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .ant-modal-content {
    border-radius: 8px !important;
  }
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  p {
    margin-bottom: 0px;
  }
  .selected-student {
    border-radius: 4px;
    border: 1px solid #c3c6cd;
    background: #fff;
    padding: 20px 16px;
    cursor: pointer;
    &:hover {
      background: #f7f8fb;
    }
    .sub-heading {
      color: #000;
      font-feature-settings: "liga" off, "clig" off;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
    .sub-heading-value {
      color: #656c7c;
      font-feature-settings: "liga" off, "clig" off;
      font-family: "Open Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
  .calculate-section {
    border-radius: 4px;
    border: 1px solid #c3c6cd;
    background: #fff;
    padding: 16px;
    .normal-txt {
      color: #656c7c;
      font-feature-settings: "liga" off, "clig" off;
      font-family: "Open Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .normal-txt-value {
      @extend .normal-txt;
      color: #000;
    }
  }
}
.wire-instruction-modal {
  .ant-modal-content {
    border-radius: 8px !important;
  }
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .main-heading {
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
  .sub-heading {
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .sub-heading-value {
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  .ml-8 {
    margin-left: 8px;
  }
  p {
    margin-bottom: 0px;
  }
}
.refund-flow-add-student-dropdown {
  .ant-dropdown-menu-item {
    border-bottom: 1px solid #dfdfdf;
  }
  .sub-heading {
    color: #000;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .sub-heading-value {
    color: #656c7c;
    font-feature-settings: "liga" off, "clig" off;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

.delete-modal {
  .ant-modal-content {
    border-radius: 8px !important;
    .ant-modal-header {
      padding: 10px 16px !important;
      border-radius: 8px 8px 0 0 !important;
      .ant-modal-title {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
      }
    }
    .ant-modal-body {
      padding: 16px !important;
      p {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
      }

      .buttons-row {
        display: flex;
        gap: 10px;
      }
    }
  }
}
