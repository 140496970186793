.External-Integration-card {
    .External-Integration-main-head {
        font-weight: bold;
        font-size: 20px;
        color: #2b323b;
    }

    .External-integration-div {
        background-color: #F7F8FB;
        padding: 15px;

        .External-card-col {
            margin-bottom: 20px;
        }

        .inner-status {
            padding-left: 30px;
        }

        .inner-card-date {
            color: #656C7C;
        }

        .view-detail-card {
            color: #2F74D3;
            font-weight: 700;

        }

        .card-date {
            color: #FF845E;
        }

    }

    .error-status-col {
        padding-left: 20px;
        padding-top: 20px;
    }

    .text-outer-card {
        background-color: #FEF2F4;

        .card-content-col {
            color: red;
            padding: 25px;
        }

        .Resend-button-col {
            padding-top: 10px;
            padding-left: 30px;
        }
    }

    .pending-status-col {
        padding-left: 12px;
    }


    .External-integration-outer-div {
        border-top: 1px solid #EDF0F5;
        margin-top: 20px;
    }

    .inner-head-row {
        margin-bottom: 20px;

        .Integration-row-head {
            color: #656C7C;
            font-Weight: 600;
            font-size: 14px;
            line-height: 20px;
            padding-top: 20px;
            padding-left: 15px;
        }

        .main-head-date {
            color: #656C7C;
            padding-top: 20px;
        }

        .status-col {
            padding-top: 20px;
            padding-left: 36px;

        }

        .head-span {
            color: #656C7C;
            padding-left: 5px;
        }
    }
}