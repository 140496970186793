.admin-doc-upload-filter {
  border-radius: 30px;
  color: white;
  background: #367bda;
}

.students-documents-section {
  padding: 40px;

  .students-documents-section-row {
    margin-bottom: 15px;
  }
}

.student-document-list-box-shadow {
  background: white;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
  border-radius: 16px;
  padding: 14px;
}

.admin-doc-list .ant-table-thead .ant-table-cell {
  background: #f7f8fb;
}

.admin-dash-documents-header {
  .ant-page-header-heading-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    color: #242a32;
  }

  .ant-btn-circle {
    font-size: 27px;
    color: #367bda !important;
  }

  .ant-page-header-heading-left {
    align-items: unset !important;
  }
}
