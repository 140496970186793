.admin-email-detail {
  background: #f2f2f2 !important;
  padding: 25px 35px;

  .email-detail-overview-section {
    background-color: #ffffff !important;
    padding: 16px;
    box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
    border-radius: 12px;
    flex-direction: column;
    align-items: center;
  }

  .email-detal-overview-sec-head-text {
    font-weight: bold;
    font-size: 16px;
    color: #2b323b;
  }

  .email-address-span {
    color: dimgrey;
  }

  .email-box {
    background-color: #ffffff !important;
    padding: 16px;
    box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.152117);
    margin-top: 20px;
    border-radius: 12px;
    flex-direction: column;
    align-items: center;
  }

  .email-address-span {
    color: dimgrey;
  }

  .email-col-container {
    margin: 20px;
  }

  .email-subject-heading {
    color: black;
    font-weight: 700;
  }

  .email-Templateid-head {
    color: #656c7c;
    font-weight: 600;
  }

  .email-status-heading {
    display: flex;
    justify-content: flex-end;
  }

  .email-status-heading .sent-status {
    color: green;
    background-color: #e4faec;
    padding: 3px 8px 5px;
    margin-top: -5px;
    font-weight: 700;
    border-radius: 4px;
  }

  .email-status-heading .failed-status {
    color: #656c7c;
    background-color: #edf0f5;
    padding: 3px 8px 5px;
    margin-top: -5px;
    font-weight: 700;
    border-radius: 4px;
  }

  .email-col-container {
    margin: 20px;
  }

  .email-date-heading {
    display: flex;
    color: #656c7c;
    font-weight: 600;
    justify-content: flex-end !important;
  }

  .email-date-heading img {
    margin-left: 12px;
    width: 26px;
    cursor: pointer;
  }

  .uparrow-icon {
    cursor: pointer;
  }

  .admin-email-timeline .ant-timeline-item {
    padding-bottom: 48px !important;
  }

  .admin-email-timeline .ant-timeline-item-head-blue {
    background: #2f74d3;
    color: #2f74d3 !important;
    border-color: #2f74d3 !important;
  }

  .admin-email-timeline .ant-timeline-item-tail {
    border-left: 2px solid #2f74d3 !important;
    left: 5px;
  }

  .admin-email-timeline .ant-timeline-item-head {
    width: 12px;
    height: 12px;
  }
}
