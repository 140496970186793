@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  height: auto;
  box-sizing: border-box;
  font-family: 'Open Sans';
}
body {
  font-family: 'Open Sans' !important;
}

.p-20 {
  padding: 20px !important;
}

.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw);
}

.h4,
h4 {
  font-size: calc(1.275rem + 0.3vw);
}

.h3,
h3 {
  font-size: calc(1.3rem + 0.6vw);
}

.h5,
h5 {
  font-size: 1.25rem;
}

.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.ant-input {
  border-radius: 8px !important;
  border: 1px solid #c3c6cd;
}

.ant-input-password {
  border-radius: 8px !important;
  border: 1px solid #c3c6cd;
}

.ant-input-number-input {
  border-radius: 8px !important;
  border: 1px solid #c3c6cd;
}

.ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number:hover {
  background: #fff;
  border-color: #ff4d4f;
  border-radius: 8px;
}

.ant-select-selector {
  border-radius: 8px !important;
  border: 1px solid #c3c6cd;
}

.ant-select {
  margin: 0 !important;
}

.ant-form-item-label label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #656c7c !important;
}

.rc-anchor-normal {
  width: 100% !important;
  height: 100% !important;
}

.measure-one-modal {
  .ant-modal-close-x {
    display: none;
  }

  .ant-modal-body {
    padding: 5px !important;
  }
}

.funded-status {
  background: #e4faec;
  border-radius: 6px;
  padding: 4px 6px;
  color: #25a050;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
}

.expired-status {
  background: #fef2f4;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #f22d55;
  padding: 4px 6px;
  text-align: center;
}

.pending-status {
  background: #fff9dc;
  border-radius: 6px;
  padding: 4px 6px;
  color: #d64417;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
}

.faded-status {
  color: #656c7c;
  background: #f7f8fb;
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

$color-primary: #367bda;
$color-white: #ffffff;
$color-black: rgb(55, 63, 80);
$color-gray: rgb(125, 135, 156);

.main-container {
  min-height: 80vh;
  max-width: 850px;
  width: auto;
  margin: 100px auto;
}

.Signup-page-labels {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #656c7c !important;
  margin-bottom: 5px;
}

.common-banner-background {
  background: linear-gradient(180deg, #f2f9fe 0%, rgba(242, 249, 254, 0) 100%);
}

.pandadoc-loader-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: auto;
  max-width: 300px;
  gap: 30px;
}

.mt-4 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-12rem {
  margin-top: 12rem;
}

.mt-36 {
  margin-top: 36px !important;
}

.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.justify-center {
  justify-content: center !important;
}
.justify-space-between {
  justify-content: space-between !important;
}
.justify-end {
  justify-content: end !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}

.align-center {
  align-items: center !important;
}

.align-baseline {
  align-items: baseline !important;
}

.avarage-inpput {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  text-align: center;
  outline: none;
}

.p-1 {
  padding: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.margin-2 {
  margin-top: -1em !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-0 {
  margin: 0 !important;
}

.gap-15 {
  gap: 15px;
}

.pt-40 {
  padding: 40px 0;
}

.pt-30 {
  padding: 30px 0;
}

.pt-15 {
  padding-top: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.flex-wrap {
  flex-wrap: wrap;
}
.edly-grey-border-button {
  border: 2px solid #656c7c !important;
  border-radius: 25px !important;
  padding: 5px 24px !important;
  color: #656c7c !important;
  min-height: 40px;
  font-weight: bold !important;
  &:hover {
    background: #ffffff !important;
    border: 2px solid #656c7c !important;
    color: #656c7c !important;
  }
}
.edly-blue-border-button {
  border: 2px solid $color-primary !important;
  border-radius: 25px !important;
  padding: 5px 24px !important;
  color: $color-primary !important;
  // height: 100% !important;
  min-height: 40px;
  font-weight: bold !important;

  &:hover {
    background: $color-primary !important;
    border: 2px solid $color-white !important;
    color: $color-white !important;
  }
}

.success-btn {
  border: 2px solid #5ccd83 !important;
  border-radius: 25px !important;
  background-color: #5ccd83 !important;

  padding: 5px 24px !important;
  color: #ffffff !important;
  min-height: 50px;
  font-weight: bold !important;
}

.edly-blue-border-button.hover-none {
  &:hover {
    background: transparent !important;
    border: 2px solid $color-primary !important;
    color: $color-primary !important;
  }
}

.w-100 {
  width: 100%;
}

.webkit-security-disc {
  -webkit-text-security: disc;
}

.edly-blue-button {
  border: 2px solid $color-primary !important;
  border-radius: 25px !important;
  padding: 5px 24px !important;
  color: $color-white !important;
  font-weight: bold !important;
  min-height: 40px;
  height: 100% !important;
  background-color: $color-primary !important;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background: $color-white !important;
    border: 2px solid $color-primary !important;
    color: $color-primary !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none;
    box-shadow: none;
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.edly-red-button {
  border: 2px solid #f22d55 !important;
  border-radius: 25px !important;
  padding: 5px 24px !important;
  color: $color-white !important;
  font-weight: bold !important;
  min-height: 40px;
  height: 100% !important;
  background-color: #f22d55 !important;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background: $color-white !important;
    border: 2px solid #f22d55 !important;
    color: #f22d55 !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none;
    box-shadow: none;
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.edly-green-button {
  border: 2px solid #5ccd83 !important;
  border-radius: 25px !important;
  padding: 5px 24px !important;
  color: $color-white !important;
  font-weight: bold !important;
  min-height: 40px;
  height: 100% !important;
  background-color: #5ccd83 !important;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background: $color-white !important;
    border: 2px solid #5ccd83 !important;
    color: #5ccd83 !important;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none;
    box-shadow: none;
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.student-status-funded {
  padding: 3px 8px 5px;
  margin: 0;
  border-radius: 4px;
  background-color: #e4faec;
  width: fit-content;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #25a050;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.student-status-pending {
  padding: 3px 8px 5px;
  margin: 0;
  border-radius: 4px;
  background: #fff9dc;
  width: fit-content;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #ff845e;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.student-status-cancelled {
  padding: 3px 8px 5px;
  margin: 0;
  border-radius: 4px;
  background-color: #fde5ea;
  width: fit-content;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #f22d55;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.success-badge-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 8px 5px;
  margin: 0;
  border-radius: 4px;
  background-color: #e4faec;
  width: fit-content;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #25a050;
}

.warning-badge-container {
  padding: 3px 8px 5px;
  margin: 0;
  border-radius: 4px;
  background: #fff9dc;
  width: fit-content;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ff845e;
}

.blue-badge-container {
  padding: 3px 8px 5px;
  margin: 0;
  border-radius: 4px;
  background: #f2f9fe;
  width: fit-content;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #2f74d3;
}

.cursor-pointer {
  cursor: pointer;
}

.error-badge-container {
  padding: 3px 8px 5px;
  margin: 0;
  border-radius: 4px;
  background-color: #fde5ea;
  width: fit-content;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #f22d55;
}

.gray-badge-container {
  padding: 3px 8px 5px;
  margin: 0;
  border-radius: 4px;
  background-color: #f7f8fb;
  width: fit-content;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #656c7c;
}
.view-hide-btns {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #2f74d3;
  cursor: pointer;
}

.decline-text-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #c31033;
  cursor: pointer;
}

.eReles {
  padding: 5px !important;
}

.eeItaK {
  height: 20px !important;
}

.jBfsAy {
  height: 35px !important;
}

.ant-form-item-control-input-content {
  #mobile:focus {
    outline: 2.5px solid #dceeff !important;
  }
}

.broder-right-gray {
  border-right: 1px solid #d7d5d5;
}

.broder-right-blue {
  border-right: 1px solid #5f9dff;
}

.privacy-policy-link:hover {
  cursor: pointer;
}

.font-16 {
  font-size: 16px !important;
}

.min-width-400 {
  min-width: 400px;
  @media screen and (max-width: 600px) {
    min-width: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  .main-container {
    width: 100%;
  }

  .broder-right-gray {
    border-right: 0px solid #d7d5d5;
  }

  .broder-right-blue {
    border-right: 0px solid #5f9dff;
  }

  .mt-100 {
    margin-top: 50px !important;
  }

  .mt-50 {
    margin-top: 25px !important;
  }
}

@media screen and (max-width: 800px) {
  .d-sm-none {
    display: none !important;
  }
}

.edly-blue-button,
.edly-red-button,
.edly-green-button {
  padding: 5px 24px !important;
  min-height: 40px;
  height: 40px !important;
}

.m-auto {
  margin: auto;
}

.align-item-center {
  align-items: center !important;
}

.mx-1 {
  margin-left: 5px;
  margin-right: 5px;
}

.m-2 {
  margin: 10px;
}

.mt-2 {
  margin-top: 12px;
}

.mr-2 {
  margin-right: 10px;
}

.font-weight-bold {
  font-weight: bold !important;
}

.pt-12 {
  padding-top: 60px;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-100 {
  margin-top: 100px;
}

.mt-50 {
  margin-top: 50px;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.border-none {
  border: none;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.spin-loading-wrp {
  width: 100%;
  text-align: center;
  margin: 2rem 0;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: $color-white !important;
}

.text-primary {
  color: $color-primary;
}

.text-align-center {
  text-align: center;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.margin-y {
  margin: 20px 0px;

  .check-softpull-checkbox {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
}

.text-blue {
  color: #2f74d3;
}

.cursor {
  cursor: pointer;
}

.font-bold {
  font-weight: 700;
}

.custom-modal {
  .modal-custom-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .modal__wrap {
    position: fixed;
    display: block;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    overflow-x: hidden;
    background-color: rgba(31, 32, 41, 0.75);
    pointer-events: none;
    opacity: 0;
    transition: opacity 250ms 700ms ease;
  }

  .visible {
    pointer-events: auto;
    opacity: 1;
    transition: all 300ms ease-in-out;
  }

  .svg-wrp {
    padding-right: 0.8rem;
    padding-top: 0.8rem;
    text-align: right;

    svg {
      color: #656c7c;
      height: 18px;
      cursor: pointer;
    }
  }

  .blue-border-button {
    border: 2px solid #367bda !important;
    border-radius: 25px !important;
    padding: 5px 24px !important;
    color: #367bda !important;
    font-weight: bold !important;
    min-height: 40px;
    height: 100% !important;
    background-color: #ffffff !important;
    transition: all 0.3s;
    cursor: pointer;
  }

  .blue-border-button:hover {
    background: #367bda !important;
    border: 2px solid #367bda !important;
    color: #ffffff !important;
  }

  .modal {
    overflow-x: hidden;
    position: relative;
    display: block;
    border-radius: 12px !important;

    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    // padding-bottom: 20px;
    background-color: #fff;
    align-self: center;
    box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
    opacity: 0;
    transform: scale(0.6);
    transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
    transform: scale(0);
  }

  .visible .modal {
    opacity: 1;
    transform: scale(1);
    transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
  }
}

.antd-pagination-student {
  display: flex;
  justify-content: center;
  margin: 15px !important;
}

.spin-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}
