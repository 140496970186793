.import-terms-continer {
  background-color: #ffffff;

  .import-terms-header {
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.04);
    padding: 0 20px;

    .header {
      display: flex;
      align-items: center;
      padding: 20px 0px;
    }
    h2 {
      color: #2b323b;
      font-size: 30px;
      font-weight: 700;
      line-height: normal;
    }
  }

  .import-terms-body {
    padding: 50px 20px;

    h2 {
      color: #242a32;
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 20px;
    }

    .table-cols {
      color: #656c7c;
      font-size: 12px;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.import-file-modal {
  .ant-modal-title {
    h2 {
      color: #2b323b;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
  }

  .import-file-modal-body {
    margin-bottom: 30px;
    .ant-upload-select {
      display: block !important;
    }
    .upload-file {
      border: 1px dashed #2f74d3;
      background-color: #f2f9fe;
      padding: 40px 0;
      cursor: pointer;
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #656c7c;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        span {
          color: #0049a4;
          font-weight: 700;
        }
      }
    }
  }
}
