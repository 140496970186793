.funding-investor-modal {
    .funding-investor-modal .ant-modal-content {
        border-radius: 8px !important;
    }

    .funding-investor-modal .ant-modal-body {
        padding: 10px 24px !important;
    }

    .funding-investor-modal .ant-modal-header {
        border-radius: 8px !important;
    }

    .funding-investor-modal .react-json-view {
        max-height: 650px;
        overflow-y: scroll;
    }

    .assign-investor-button {
        border: 2px solid #367bda !important;
        border-radius: 25px !important;
        padding: 8px 20px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: flex-end !important;
        color: #ffffff !important;
        font-weight: bold !important;
        background-color: #367bda !important;
        cursor: pointer;
    }

    .assign-investor-button:hover {
        background: #ffffff !important;
        border: 2px solid #367bda !important;
        color: #367bda !important;
    }

    .assign-investor-button:disabled {
        color: rgba(0, 0, 0, 0.25) !important;
        border-color: #d9d9d9 !important;
        background: #f5f5f5 !important;
        text-shadow: none;
        box-shadow: none;
        opacity: 0.7;
        cursor: not-allowed;
    }
}