.identity-heading-row {
  align-items: center;
  // min-height: 32px;
  margin: 18px 0px 8px;
  padding-left: 16px;

  .identity-heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #2b323b;
  }
}

.identity-data-main-row {
  justify-content: space-between;
  align-items: center;

  .identity-data-col {
    min-width: 100%;
    // min-height: 50px;
    padding: 12px 16px 6px 16px !important;
    border-bottom: 1px solid #edf0f5;

    .identity-data-inner-row {
      justify-content: space-around;

      .identity-data-label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #656c7c;
        margin: 0px !important;
      }

      .identity-data-date {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #656c7c;
        margin: 0px !important;
      }

      .right-span {
        display: flex;
        justify-content: flex-end;

        .identity-data-value {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
          margin: 0px !important;
        }
      }
    }
  }

  .red-flags-main-col {
    background: #fff9dc;

    .flags-heading {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #2b323b;
      padding: 16px 16px 8px;
    }

    .flag-row {
      padding: 2px 16px;
      align-items: center;

      .flag-col {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #2b323b;

        .red-mark {
          color: #f22d55 !important;
          margin-right: 1%;
        }
      }

      .view-doc-col {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        img {
          margin-left: 2%;
        }
      }
    }

    .doc-main-row {
      margin-top: 16px;

      .doc-main-col {
        padding: 12px 16px 6px 16px !important;
        // min-height: 50px;
        border-bottom: 1px solid #edf0f5;

        .docs-col {
          display: flex;
          align-items: center;
          gap: 8px;

          .doc-label {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #656c7c;
          }
        }
      }
    }
  }
}

.reason-main-col {
  background: #f7f8fb;
  margin-top: 4px;

  .reason-heading {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #f22d55;
    padding: 16px 16px 8px;
  }

  .approved-reason-heading {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #25a050;
    padding: 16px 16px 8px;
  }

  .reason-para {
    margin: 0px !important;
    padding: 0px 16px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2b323b;
  }
}
